import React, {Component} from 'react';
import Form from "./Form"
import Permissions from "./Permissions";

class SecondColumn extends Component {

    constructor(props) {
        super(props);
    }


    render() {
        const {formValues, errors, selectedUser, passwordUpdated, isNewUser,confirmPasswordEdit,
        handleInputChange,permissionsList ,userForm,handlePermissionSelected,selectedPermission, handleDropdownChange} = this.props;
        return (
            <div className="SecondColumn">
                {userForm &&
                <Form handleInputChange={handleInputChange} handleDropdownChange={handleDropdownChange} formValues={formValues} selectedUser={selectedUser}
                      errors={errors} isNewUser={isNewUser} passwordUpdated={passwordUpdated}
                      confirmPasswordEdit={confirmPasswordEdit}
                />
                }
                {permissionsList &&
                    <Permissions handlePermissionSelected={handlePermissionSelected} selectedPermission={selectedPermission}/>
                }
            </div>

        )

    }

}

export default SecondColumn;