import React, {Component} from 'react';
import matchSorter from "match-sorter"
import ReactTable from "react-table";
import "react-table/react-table.css";
import ComexCompaniesCuits from "./ComexCompaniesCuits"
import {FIRST_LEVEL_CUIT} from "./utils"

class ComexCompanies extends Component {

    constructor(props) {
        super(props);

        this.state = {
            height: 0,
        }
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.addCompany = this.addCompany.bind(this);
    }

     componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({height: window.innerHeight - 150});
    }

    componentWillMount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    customOptionsFilterMethod = (filter, row) => {
      if(!row[filter.id]) {
          return false
      }
      else{
       return row[filter.id] == filter.value
      }
    };

    addCompany = (row) => {
        let cuitFamily = {};
        cuitFamily.client = row;
        this.props.onAddCompanyCuit(cuitFamily,"");
    }

    render() {
        const {height} = this.state;
        const {companies,onEditCompany,onDeleteCompany,onDeleteCompanyCuit,onAddCompanyCuit,onEditCompanyCuit} = this.props;
        return (
            <ReactTable
                data={companies}
                expander={true}
                filterable
                showPagination={false}
                noDataText="Sin clientes asignados"
                columns={[
                    {
                        columns: [
                            {
                                Header: "Empresa",
                                id: "name",
                                accessor: "name",
                                filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {keys: ["name"]}),
                                filterAll: true
                            },
                            {
                                Header: "Cuit",
                                accessor: "cuit",
                                filterMethod: (filter, row) =>
                                    row[filter.id].startsWith(filter.value)
                            },
                            {
                                Header: "Tipo",
                                accessor: "user_type",
                                filterMethod: (filter, row) =>
                                    row[filter.id].startsWith(filter.value)
                            },
                             {
                                Header: "Certificado",
                                accessor: "cert",
                                 filterMethod:  (filter, row) => this.customOptionsFilterMethod(filter, row),
                            },
                            {
                                Header: "",
                                filterable: false,
                                Cell: row => (
                                <div>
                                    <span className="icon" onClick={() => onEditCompany(row.original)}>
                                        <i className="fas fa-edit" style={{marginRight: 5, cursor: 'pointer'}}/>
                                    </span>
                                    <span className="icon" onClick={() => onDeleteCompany(row.original)}>
                                        <i className="fas fa-trash" style={{marginRight: 5, cursor: 'pointer'}}/>
                                    </span>
                                     <span className="icon" onClick={() => this.addCompany(row.original)}>
                                        <i className="fas fa-plus" style={{marginRight: 5, cursor: 'pointer'}}/>
                                    </span>
                                </div>)
                            },
                            ]
                    },
                ]}
                pageSize={companies.length > 10? companies.length: 10}
                className="-striped -highlight"
                style={{
                    height: height + 'px'
                }}
                SubComponent={(row) => <ComexCompaniesCuits client={row.original} level={FIRST_LEVEL_CUIT}
                                                            cuits={row.original.cuits}
                                                            onEditCompanyCuit={onEditCompanyCuit}
                                                            onAddCompanyCuit={onAddCompanyCuit}
                                                            onDeleteCompanyCuit={onDeleteCompanyCuit}/>}
            />
        )

    }

}
export default ComexCompanies;