import React, {Component} from 'react';
import Header from "./Header";

class Reports extends Component {

  constructor(props) {
    super(props);

    this.state = {
      frameHeight: "85%",
    }

  }

  componentDidMount() {
    let windowHeight = window.innerHeight;
    let headerHeight = document.getElementById("heroheader").getBoundingClientRect().height;

    let h = windowHeight - headerHeight + "px";
    document.getElementById("frame").setAttribute("height", h);
  }

  render() {

    return (

        <div style={{height: "100vh"}}>

          <Header/>

          <iframe className="iframe-report" id="frame"
                  src="https://app.powerbi.com/view?r=eyJrIjoiN2YzODQ2NjgtNGI1Ni00NzZiLWEzMzMtMTA3MmZiMTk1OTZmIiwidCI6IjA5NTdhNzExLTY3ZjgtNDUyNy1iODY2LTMzZWU4ZmE5NGJlOCJ9"
                  allowFullScreen={true}/>

        </div>

    )

  }

}

export default Reports