import React, {Component} from 'react';
import matchSorter from "match-sorter"
import ReactTable from "react-table";
import "react-table/react-table.css";

class EveCompanies extends Component {

    constructor(props) {
        super(props);

        this.state = {
            height: 0,
        }
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

     componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({height: window.innerHeight - 250});
    }

    componentWillMount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    customOptionsFilterMethod = (filter, row) => {
      if(!row[filter.id]) {
          return false
      }
      else{
       return row[filter.id] == filter.value
      }
    };

    render() {
        const {height} = this.state;
        const {companies,onEditCompany,onDeleteCompany} = this.props;
        console.log(companies)
        return (
            <ReactTable
                data={companies}
                filterable
                showPagination={false}
                noDataText="Sin clientes asignados"
                columns={[
                    {
                        columns: [
                            {
                                Header: "Empresa",
                                id: "name",
                                accessor: "name",
                                filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {keys: ["name"]}),
                                filterAll: true
                            },
                            {
                                Header: "Cuit",
                                accessor: "cuit",
                                filterMethod: (filter, row) =>
                                    row[filter.id].startsWith(filter.value)
                            },
                            {
                                Header: "Clave Fiscal",
                                accessor: "password",
                                filterMethod: (filter, row) =>
                                    row[filter.id].startsWith(filter.value)
                            },
                            {
                                Header: "",
                                filterable: false,
                                Cell: row => (
                                <div>
                                    <span className="icon" onClick={() => onEditCompany(row.original)}>
                                        <i className="fas fa-edit" style={{marginRight: 5, cursor: 'pointer'}}/>
                                    </span>
                                    <span className="icon" onClick={() => onDeleteCompany(row.original)}>
                                        <i className="fas fa-trash" style={{marginRight: 5, cursor: 'pointer'}}/>
                                    </span>
                                </div>)
                            },
                            ]
                    },
                ]}
                pageSize={companies.length > 10? companies.length: 10}
                className="-striped -highlight"
                style={{
                    height: height + 'px'
                }}
            />
        )

    }

}
export default EveCompanies;