import React, {Component} from 'react';
import {invalidCUIT, isCuitValid, mustCompleteField, nameTooLarge,invalidCert} from "./Utils";
import {COMEX_COMPANIES} from "./UserFormColumns/Constants"
import {FIRST_LEVEL_CUIT,SECOND_LEVEL_CUIT} from "./UserFormColumns/utils"

class EditCompany extends Component {

    constructor(props) {
        super(props);
        this.state = {

            editCompanyValues: {
                name: props.level === FIRST_LEVEL_CUIT ? props.company.firstLevel.name : props.level === SECOND_LEVEL_CUIT ? props.company.secondLevel.name : props.company.name,
                cuit: props.level === FIRST_LEVEL_CUIT ? props.company.firstLevel.cuit : props.level === SECOND_LEVEL_CUIT ? props.company.secondLevel.cuit : props.company.cuit,
                cert: props.level === FIRST_LEVEL_CUIT ? props.company.firstLevel.cert ? props.company.firstLevel.cert : 1 : props.type === COMEX_COMPANIES? props.company.cert ? props.company.cert : 1 : 1 ,
                user_type: props.level === FIRST_LEVEL_CUIT ? props.company.firstLevel.user_type ? props.company.firstLevel.user_type : "IMEX" : props.type === COMEX_COMPANIES? props.company.user_type ? props.company.user_type  : "IMEX" : "IMEX",
                index: props.index,
                cuits: props.company.cuits? props.company.cuits : [],
                operationsForCuit:  props.level === FIRST_LEVEL_CUIT ? props.company.firstLevel.user_type ? props.company.firstLevel.user_type.length > 0 : false : props.operationsForCuit,
            },

            companyErrors: {
                name: "",
                cuit: "",
                cert: "",
            },

        }

    }

    saveCompany = () => {

        if (this.validateCompanyInputs()) {
            return;
        }

        let {onSaveCompany, disableEditCompanyForm,type,level,indexFamily} = this.props;
        let {editCompanyValues} = this.state;

        const newCuit = {"cuit":editCompanyValues.cuit,"name":editCompanyValues.name,"cuits":[],
            "cert":editCompanyValues.operationsForCuit? editCompanyValues.cert: null,
            "user_type":editCompanyValues.operationsForCuit? editCompanyValues.user_type: null }

        if (level){
            const {company} = this.props;
            onSaveCompany(company,level,indexFamily,newCuit);
        }
        else {
            let {editCompanyValues} = this.state;
            if (!editCompanyValues.operationsForCuit){
                editCompanyValues.cert = null;
                editCompanyValues.user_type = null;
            }
            onSaveCompany(editCompanyValues,type);
        }

         disableEditCompanyForm();
    };

    getCheckbox = () => {
        const {editCompanyValues} = this.state;
        return editCompanyValues.operationsForCuit?
            <input type="checkbox" checked onChange={this.handleCheckClick}/> :
            <input type="checkbox" onChange={this.handleCheckClick}/>
    }

    handleCompanyChange = event => {

        const target = event.target;
        const value = target.value;
        const name = target.name;
        let {editCompanyValues, companyErrors} = this.state;

        editCompanyValues[name] = value;

        companyErrors[name] = "";

        this.setState({editCompanyValues: editCompanyValues});

    };

    handleCheckClick = (event) => {
        let {editCompanyValues} = this.state;
        editCompanyValues.operationsForCuit = event.target.checked;
        this.setState({editCompanyValues:editCompanyValues});
    }

    validateCompanyInputs = () => {

        const {editCompanyValues} = this.state;
        let cuit = editCompanyValues.cuit.replace(/-/g, "");

        let hasErrors = false;
        let errors = {
            name: "",
            cuit: "",
            cert: "",
        };

        if (editCompanyValues.name.length === 0) {
            errors.name = mustCompleteField;
            hasErrors = true;
        }

        if (editCompanyValues.name.length > 40) {
            errors.name = nameTooLarge;
            hasErrors = true;
        }

        if (cuit.length === 0) {
            errors.cuit = mustCompleteField;
            hasErrors = true;
        } else if (cuit.length !== 11 || !isCuitValid(cuit)) {
            errors.cuit = invalidCUIT;
            hasErrors = true;
        }

        if (editCompanyValues.operationsForCuit){
            if (editCompanyValues.cert.length === 0) {
                errors.cert = mustCompleteField;
                hasErrors = true;
            }else if (editCompanyValues.cert <= 0) {
                errors.cert = invalidCert;
                hasErrors = true;
            }
        }

        this.setState({companyErrors: errors});

        return hasErrors

    };

    render() {

        let {companyErrors, editCompanyValues} = this.state;
        const {disableEditCompanyForm} = this.props;
        return (

            <div className="box">

                <div className="field">
                    <label className="label is-pulled-left">Nombre</label>
                    <div className="help is-danger is-pulled-right"
                         hidden={companyErrors.name === ""}>{companyErrors.name}</div>
                    <input value={editCompanyValues.name}
                           name="name"
                           onChange={this.handleCompanyChange}
                           className={companyErrors.name !== "" ? "input is-danger" : "input"} type="text"
                           placeholder="Nombre"/>
                </div>
                <div className="field">
                    <label className="label is-pulled-left">CUIT</label>
                    <div className="help is-danger is-pulled-right"
                         hidden={companyErrors.cuit === ""}>{companyErrors.cuit}</div>
                    <input value={editCompanyValues.cuit}
                           name="cuit"
                           onChange={this.handleCompanyChange}
                           className={companyErrors.cuit !== "" ? "input is-danger" : "input"} type="text"
                           placeholder="CUIT"/>
                </div>
                {editCompanyValues.operationsForCuit  &&
                <div className="field">
                    <label className="label is-pulled-left">CERT</label>
                    <div className="help is-danger is-pulled-right"
                         hidden={companyErrors.cert === ""}>{companyErrors.cert}</div>
                    <input value={editCompanyValues.cert}
                           type="number"
                           name="cert"
                           pattern="[0-9]"
                           onChange={this.handleCompanyChange}
                           className={companyErrors.cert !== "" ? "input is-danger" : "input"}
                           placeholder="CERT"/>
                </div>
                }
                {editCompanyValues.operationsForCuit  &&
                <div className="field">
                    <label className="label is-pulled-left">Tipo</label>
                    <div className="select is-small">
                        <select id="type" onChange={this.handleCompanyChange} name="user_type"
                                value={editCompanyValues.user_type} style={{width: '100vh'}}>
                            <option>DESP</option>
                            <option>IMEX</option>
                            <option>USUD</option>
                            <option>IEOC</option>
                        </select>
                    </div>
                </div>
                }
                {(this.props.level === FIRST_LEVEL_CUIT || this.props.type === COMEX_COMPANIES) &&
                <div className="field">
                    <label className="checkbox is-pulled-left">
                        {this.getCheckbox()}Buscar
                        operacions para este cuit
                    </label>
                </div>
                }
                <div className="buttons is-right">
                    <div className="button is-danger is-outlined"
                         onClick={disableEditCompanyForm}>
                        <span>Cancelar</span>
                        <span className="icon is-small">
                      <i className="fas fa-times"/>
                    </span>
                    </div>
                    <div className="button is-info"
                         onClick={this.saveCompany}>
                        <span>Aceptar</span>
                        <span className="icon is-small">
                      <i className="fas fa-check"/>
                    </span>
                    </div>
                </div>
            </div>

        )

    }

}

export default EditCompany;