import React, {Component} from 'react';
import Header from "./Header";
import Navigation from "./Navigation";
import UploadExpirations from "./UploadExpirations";
import {getCSVFile, onRequestError} from "./Services";
import download from "downloadjs";

class Expirations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "expirations",
    }
  }

  downloadCSV = () => {

    this.setState({downloading: true});

    getCSVFile()
        .then(
            res => {
              const content = res.headers['content-type'];
              download(res.data, 'expirations.csv', content);
              this.setState({downloading: false});
            }
        )
        .catch(
            error => {
              onRequestError(error);
              this.setState({downloading: false});
            }
        )

  };

  render() {

    return (

        <div>

          <Header activeTab="expirations"/>

          <div className="box box-under-navigation box-wrap-input center-horizontal-objects">

            <h4 className="title is-4 title-margin-down">
              Subir archivo de vencimientos.
            </h4>

            <h5 className="subtitle is-5 subtitle-margin-down">
              El archivo debe ser de tipo csv.<br/>
              Delimitador: pipe ("|").<br/>
              Campos en orden: Titular, Despachante, Destinación, Depositario Fiel y Fecha Oficialización.
            </h5>

            <UploadExpirations/>

            <h4 className="title is-4 title-margin-down-over-button title-margin-up">
              Descargar archivo CSV para completar
            </h4>

            <button className={this.state.downloading ? "button is-info is-loading" : "button is-info"}
                    onClick={this.downloadCSV}
                    type="submit">
              Descargar
            </button>
          </div>

        </div>

    )

  }

}

export default Expirations;