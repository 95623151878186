import React, {Component} from 'react';
import {onRequestError, uploadOficialized} from "./Services";
import {selectCSVFile} from "./Utils";

class UploadOficialized extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedFile: null,
      uploading: false,
    }

  }

  onFileSelected = (e) => {
    this.setState({
      selectedFile: e.target.files[0]
    })
  };

  upload = () => {
    this.setState({uploading: true});
    const data = new FormData();
    data.append('file', this.state.selectedFile);
    uploadOficialized(data)
        .then(
            res => {
              alert('El archivo se ha subido exitosamente');
              this.setState({uploading: false});
            }
        )
        .catch(
            error => {
              onRequestError(error);
              this.setState({uploading: false});
            }
        );
  };

  render() {

    return (

        <div>

          <nav className="level margin-down-objects">
            <div className="level-left center-horizontal-objects">

              <div className="level-item">

                <div className="file has-name">
                  <label className="file-label">
                    <input className="file-input" type="file" name="file" onChange={this.onFileSelected}/>
                    <span className="file-cta">
                        <span className="file-icon">
                          <i className="fas fa-upload"/>
                        </span>
                        <span className="file-label">
                        </span>
                      </span>
                    <span className="file-name">
                      {this.state.selectedFile ? this.state.selectedFile.name : selectCSVFile}
                    </span>
                  </label>
                </div>

              </div>

              <button className={this.state.uploading ? "button is-info is-loading" : "button is-info"}
                      onClick={this.upload}
                      disabled={!this.state.selectedFile}>
                Subir
              </button>

            </div>

          </nav>

        </div>

    )

  }

}

export default UploadOficialized;