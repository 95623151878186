import React, {Component} from 'react';
import {
    PERMISSIONS_TITLE, PERMISSIONS_STATE_TITLE, PERMISSIONS_WITH_ADDITIONAL_CONFIGURATION,COMEX_COMPANIES,
    EVE_COMPANIES
} from "./Constants"
import {formatWord} from "./utils"
import CompaniesTab from "./CompaniesTab"
import AttachmentDropdown from './AttachmentDropdown'

class ThirdColumn extends Component {

    constructor(props) {
        super(props);
        this.onSelectState = this.onSelectState.bind(this);
        this.getExtraConfiguration = this. getExtraConfiguration.bind(this);
        this.handlePowerBiLink = this.handlePowerBiLink.bind(this);
    }

    getLabel(permission,selectedPermission){
        return<label className="checkbox">
                <input type="checkbox" name="answer" onClick={this.onSelectState} checked={permission[selectedPermission] != undefined ? permission[selectedPermission] : false }/> {selectedPermission == 'moa_operations' ? 'MOA' : 'Sección habilitada'}</label>

    }

    onSelectState (event){
        const {onChangePermissionState,selectedPermission} = this.props;
        onChangePermissionState(selectedPermission);
    }

     handlePowerBiLink(event){
        const {handlePowerBiLink} = this.props;
        handlePowerBiLink(event.target.value);
    }


     getPowerByLabel(permission,errors){
        return permission["reports"] ? <input className={errors.powerbi == "" ? "input is-small" : "input is-small is-danger"}
                                       type="text" placeholder="Power BI Link" name="link"
                                       value={this.props.formValues.powerbi}
                                              onChange={this.handlePowerBiLink}/> :
            <input className = "input is-small"
                                       type="text" placeholder="Power Bi Link" name="link"
                                       value="" disabled/>
    }

    hasExtraConfiguration(permission){
         const index = PERMISSIONS_WITH_ADDITIONAL_CONFIGURATION.findIndex(x => x === permission);
         return index != -1;
    }

    getExtraConfiguration(permission){
        const {formValues,editCompany,deleteCompany,addCompany,onDeleteCompanyCuit,
            onAddCompanyCuit,onEditCompanyCuit, handleDropdownChange} = this.props;
        switch (permission) {
            case "eventanilla":
                return <CompaniesTab companies={formValues.eve_companies} editCompany={editCompany}
                                     deleteCompany={deleteCompany} addCompany={addCompany} type={EVE_COMPANIES}
                                     onEditCompanyCuit={onEditCompanyCuit} onAddCompanyCuit={onAddCompanyCuit}
                                     onDeleteCompanyCuit={onDeleteCompanyCuit}
                />
                break;
            case "Empresas":
                return <CompaniesTab companies={formValues.comex_companies} editCompany={editCompany}
                                     deleteCompany={deleteCompany} addCompany={addCompany} type={COMEX_COMPANIES}
                                     onEditCompanyCuit={onEditCompanyCuit} onAddCompanyCuit={onAddCompanyCuit}
                                     onDeleteCompanyCuit={onDeleteCompanyCuit}
                />
            case "moa_operations":
                return <AttachmentDropdown attachment={formValues.attachment_source}
                                 handleDropdownChange={handleDropdownChange}/>
                break;
        }
    }

    render() {
        const {selectedPermission,permissions,errors} = this.props;
        return (
            <div className="ThirdColumn">
                {selectedPermission != "Empresas" &&
                <div>
                <div className="myTitle">{formatWord(PERMISSIONS_TITLE[selectedPermission])}</div>
                <div className="leftCheckboxes">
                    {this.getLabel(permissions,selectedPermission)}
                </div>
                </div>
                }
                {selectedPermission == "reports" &&
                    <div id="powerBiLink">
                    <nav className="level is-marginless">
                        <div className="level-left">
                            <label style={{marginRight: '10px', marginBottom: '6px'}}
                                   className="label is-small is-pulled-left separate-inputs">
                                Link
                            </label>
                        </div>
                        <div className="level-right is-paddingless">
                            <label className="help is-danger formLoginErrors" hidden={errors.powerbi === ""}>{errors.powerbi}</label>
                        </div>
                    </nav>
                    <div className="control has-icons-left">
                        {this.getPowerByLabel(permissions,errors)}
                    </div>
                </div>
                }
                {this.hasExtraConfiguration(selectedPermission) &&
                <div className="extraConfiguration">
                    {this.getExtraConfiguration(selectedPermission)}
                </div>
                }
            </div>
        )
    }
}

export default ThirdColumn;