import React, {Component} from 'react';
import {USER_SETTINGS} from "./Constants"
import {formatWord} from "./utils";

class FirstColumn extends Component {

    constructor(props) {
        super(props);
    }


    render() {
        const {columnClicked} = this.props
        return (
            <div>
                {USER_SETTINGS.map((setting) => {
                    return(
                        <label className="permissions" onClick={() => columnClicked(setting.value)}
                                       key={setting.value} id={this.props[setting.value] ? "myPermissionSelected" : ""}>
                                       {setting.label}
                        </label>
                    )
                })}
            </div>
        )
    }
}

export default FirstColumn;