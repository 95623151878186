import React, {Component} from 'react';


class StatsList extends Component {

  constructor(props) {
    super(props);
  }

  render() {

    return (
        <div className="tags has-addons" id="tagDiv" >
           <div className="tagElement">
                <span className="tag is-link is-so-small">Usuarios Totales</span>
                <span className="tag is-black is-so-small">{this.props.totalUsers}</span>
           </div>
           <div className="tagElement">
               <span className="tag is-info is-small">Usuarios Eve</span>
               <span className="tag is-black is-small">{this.props.eveUsers}</span>
           </div>
           <div className="tagElement">
                <span className="tag is-success is-small">Empresas Eve</span>
                <span className="tag is-black is-small">{this.props.totalEveCompanies}</span>
           </div>
            <div className="tagElement">
                <span className="tag is-success is-small">Empresas Comex</span>
                <span className="tag is-black is-small">{this.props.totalComexCompanies}</span>
            </div>
        </div>
    )

  }

}

export default StatsList;