import React, {Component} from 'react';
import matchSorter from "match-sorter"
import ReactTable from "react-table";
import "react-table/react-table.css";
import {getCompanies, getUsers, onRequestError} from './Services'
import Header from "./Header";
import Loading from "./Loading"



class UserTable extends Component {

    constructor(props) {
        super(props);

        this.state = {
            userList: [],
            height: 0,
            loading:false,
        }

        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({height: window.innerHeight - 55});
    }

    componentWillMount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
        this.getUsers();
    }



    getUsers() {
        this.setState({loading:true});
        getCompanies()
            .then(
                response => {
                    let userList = response.data;
                    let companiesList = [];
                    userList.forEach(function (user) {
                        let allCompanies = user.eve_companies.concat(user.comex_companies)
                        allCompanies.forEach(function (company) {
                           let companyToList = {
                               name: user.name,
                               phone: user.phone,
                               company:company.name,
                               cuit:company.cuit,
                           }
                           companiesList.push(companyToList);
                       });

                    });
                    this.setState({userList: companiesList});
                    this.setState({loading:false});

                }
            )
            .catch(
                error => {
                    onRequestError(error)
                    this.setState({loading:false});
                }
            );
    }

    customOptionsFilterMethod = (filter, row) => {
      if(!row[filter.id]) {
          return false
      }
      else{
       return row[filter.id].startsWith(filter.value)
      }

    }

    render() {
        const {height, loading} = this.state;
        return (
            <div className="dashboard">
                 <Header activeTab="companies"/>
            <div className="reactTableDiv">
                <ReactTable
                    data={this.state.userList}
                    filterable
                    columns={[
                        {
                            columns: [
                                {
                                    Header: "Empresa",
                                    id: "company",
                                    accessor: "company",
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {keys: ["company"]}),
                                filterAll: true
                                },
                                {
                                    Header: "Cuit",
                                    id: "cuit",
                                    accessor: "cuit",
                                    filterMethod: (filter, row) =>
                                        row[filter.id].startsWith(filter.value)
                                },
                                {
                                    Header: "Clave Fiscal",
                                    id: "fiscal_key",
                                    accessor: "fiscal_key",
                                    filterMethod: (filter, row) =>
                                        row[filter.id].startsWith(filter.value)
                                },
                                {
                                    Header: "Usuario",
                                    id: "name",
                                    accessor: "name",
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {keys: ["name"]}),
                                    filterAll: true
                                },
                                {
                                    Header: "Telefono",
                                    id: "phone",
                                    accessor: "phone",
                                    filterMethod:  (filter, row) => this.customOptionsFilterMethod(filter, row),
                                },
                            ]
                        },
                    ]}
                    defaultPageSize={10}
                    className="-striped -highlight"
                    style={{
                        height: height + 'px'
                    }}
                />
            </div>
                {loading && <Loading/>}
        </div>
        )
    }

}

export default UserTable;