export const USER_SETTINGS = [{label:"Datos del usuario",value:"userForm"},{label:"Configuración",value:"permissionsList"}];

export const PERMISSIONS_TITLE = {
    eventanilla: "Eventanilla", expirations: "Vencimientos", internal_notifications:"Notificaciones",
    stats:"Estadisticas", settlement_reports:"Informes", alerts: "Alertas", dien_operations: "Dien", refunds: "Reintegros", ssee: "Servicios Especiales",
    reports: "Reportes", moa_operations:"Operaciones", simi_lna:"SIMI_LNA"};


export const PERMISSIONS_STATE_TITLE = "SECCION HABILITADA"

export const PERMISSIONS_WITH_ADDITIONAL_CONFIGURATION = ["eventanilla", "refunds", "reports","Empresas", "moa_operations"];

export const COMEX_COMPANIES = "Empresas Comex";
export const EVE_COMPANIES = "Empresas Eve";

export const PERMISSIONS = [{value:"eventanilla", label:"Eventanilla"},{value:"expirations", label:"Vencimientos"},
{value:"internal_notifications", label:"Notificaciones"},{value:"stats", label:"Estadisticas"},{value:"settlement_reports", label:"Informes"},
{value:"alerts", label:"Alertas"},{value:"moa_operations", label:"Operaciones"},{value:"dien_operations", label:"Dien"}, {value:"refunds", label:"Reintegros"},
    {value:"ssee", label:"Servicios Especiales"},{value:"reports", label:"Reportes"},
{value:"Empresas", label:"Arbol de Usuarios"},{value:"simi_lna", label:"SIMI-LNA"}];


export const COMEX = 'comex';
export const CONTABLE = 'accountant'

export const TYPE_CONTABLE = 0
export const TYPE_COMEX = 1
export const TYPE_SIDOM = 2

export const ATTACHMENT_SOURCE = {
    NONE : {value: 0, label: 'Ninguna'},
    OURS : {value: 1, label: 'DASA'},
    SIDOM : {value: 2, label: 'SIDOM'}
}