import React, {Component} from 'react';
import Header from "./Header";
import Navigation from "./Navigation";
import UploadOficialized from "./UploadOficialized";
import {onRequestError} from "./Services";

class Oficialized extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: "oficialized"
    }
  }

  render() {

    return (

        <div>

          <Header activeTab="oficialized"/>

          <div className="box box-under-navigation box-wrap-input center-horizontal-objects">

            <h4 className="title is-4 title-margin-down">
              Subir archivo de oficializados
            </h4>

            <h5 className="subtitle is-5 subtitle-margin-down">
              El archivo debe ser del tipo csv. <br/>
              Delimitador: coma (",").<br/>
              Campos en orden: Titular, Despachante, Destinación, Depositario Fiel y Fecha Oficialización.
            </h5>

            <div>
              <UploadOficialized/>
            </div>

          </div>

        </div>

    )

  }

}

export default Oficialized;