import storage from "local-storage-fallback";
import history from "./History";

import {FIRST_LEVEL_CUIT,SECOND_LEVEL_CUIT} from "./UserFormColumns/utils"

export const mustCompleteField = "Debe completar este campo";
export const nameTooLarge = "Nombre demasiado largo";
export const invalidEmail = "E-mail inválido.";
export const invalidCUIT = "El CUIT no es válido";
export const invalidPhoneNumber = "El telefono ingresado no es valido";
export const mailAlreadyUsed = 'Ya existe un usuario con ese mail';
export const sessionExpired = 'Sesión expirada';
export const selectCSVFile = 'Seleccione un archivo .csv';
export const customerDoesNotExist = 'El despachante no existe';
export const userHasBeenModified = 'El usuario ha sido modificado. ¿Está seguro de que desea continuar sin guardar los cambios?';
export const invalidCert = "El certificado de una empresa debe ser mayor a 0";


export function validateEmail(email) {
  let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function logOut() {
  storage.setItem('userName', '');
  storage.setItem('userImg', '');
  storage.setItem('token', '');
  history.push("/login");
}

export function formatName(name) {

  let splitName = name.split(" ");
  let wordsAmount = splitName.length;
  let result = [];

  for (let i = 0; i < wordsAmount; i++) {
    let capitalized = splitName[i].charAt(0).toUpperCase() + splitName[i].slice(1).toLowerCase();
    result.push(capitalized);
  }

  return result.join(" ");

}

export function isCuitValid(cuit) {
  let serie = [5,4,3,2,7,6,5,4,3,2];
  let sum = 0;
  let checker = parseInt(cuit.charAt(10));

  for (let i = 0; i < 10; i++) {
    sum += parseInt(cuit.charAt(i)) * serie[i];
  }

  let mod11 = sum % 11;
  let final = 11 - mod11;

  return (final === 11 && checker === 0) || final === checker;
}

export const config = {
  API_URL: process.env.REACT_APP_ENDPOINT,
  GOOGLE_CLIENT_ID: "2719982174-a08pin1qt8omcabj6eap9l1r1pks3074.apps.googleusercontent.com",
};

export function sortCompaniesByName (companies){
  companies.sort((a, b) => a.name.localeCompare(b.name));
};

export function saveCuitInCompany(companies,cuitFamily,level,indexFamily,newCuit){
  switch (level) {
    case FIRST_LEVEL_CUIT:
      if (indexFamily.firstLevel === -1) {
        companies[indexFamily.client].cuits.push(newCuit);
      } else {
        companies[indexFamily.client].cuits[indexFamily.firstLevel].name = newCuit.name;
        companies[indexFamily.client].cuits[indexFamily.firstLevel].cuit = newCuit.cuit
        companies[indexFamily.client].cuits[indexFamily.firstLevel].cert = newCuit.cert;
        companies[indexFamily.client].cuits[indexFamily.firstLevel].user_type = newCuit.user_type
      }
      sortCompaniesByName(companies[indexFamily.client].cuits);
      break;
    case SECOND_LEVEL_CUIT:
       if (indexFamily.secondLevel === -1) {
        companies[indexFamily.client].cuits[indexFamily.firstLevel].cuits.push(newCuit);
      } else {
        companies[indexFamily.client].cuits[indexFamily.firstLevel].cuits[indexFamily.secondLevel].name = newCuit.name;
        companies[indexFamily.client].cuits[indexFamily.firstLevel].cuits[indexFamily.secondLevel].cuit = newCuit.cuit;
      }
       sortCompaniesByName(companies[indexFamily.client].cuits[indexFamily.firstLevel].cuits);
      break;
  }
};

export function deleteCuitInCompany(companies,cuitFamily,level){
  const indexFamily = cuitIndexFamily(companies,cuitFamily,level);
  switch (level) {
    case FIRST_LEVEL_CUIT:
      companies[indexFamily.client].cuits.splice(indexFamily.firstLevel, 1);
      break;
    case SECOND_LEVEL_CUIT:
      companies[indexFamily.client].cuits[indexFamily.firstLevel].cuits.splice(indexFamily.secondLevel, 1);
      break;
  }
};

export function cuitIndexFamily(companies,cuitFamily,level){
  let indexFamily = {};
  switch (level) {
    case FIRST_LEVEL_CUIT:
      indexFamily.client = indexByCuit(companies,cuitFamily.client);
      indexFamily.firstLevel = indexByCuit(companies[indexFamily.client].cuits,cuitFamily.firstLevel);
      return indexFamily;
      break;
    case SECOND_LEVEL_CUIT:
      indexFamily.client = indexByCuit(companies,cuitFamily.client);
      indexFamily.firstLevel = indexByCuit(companies[indexFamily.client].cuits,cuitFamily.firstLevel);
      indexFamily.secondLevel = indexByCuit(companies[indexFamily.client].cuits[indexFamily.firstLevel].cuits,cuitFamily.secondLevel);
      return indexFamily;
      break;
  }
};

export function indexByCuit(list,client) {
  if (list.length === 0){return -1};
  const index = list.findIndex(x=>x.cuit === client.cuit);
  return index;
};

export function downloadFile(blob, filename){

    // 2. Create blob link to download
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement('a');
    link.href = url;
    const name = filename || 'file'
    link.setAttribute('download', filename);
    // 3. Append to html page
    document.body.appendChild(link);
    // 4. Force download
    link.click();
    // 5. Clean up and remove the link
    link.parentNode.removeChild(link);
    return ;
}