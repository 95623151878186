import React, {Component} from 'react';
import {PERMISSIONS} from "./Constants"
import {formatWord} from "./utils"

class Permissions extends Component {

    constructor(props) {
        super(props);
        this.onChangeState = this.onChangeState.bind(this);
    }

    onChangeState(permission){
        const {handlePermissionSelected} = this.props;
        handlePermissionSelected(permission);
    }

    render() {
        const {selectedPermission} = this.props;
        return (
            <div>
                <div className="myTitle">Secciones</div>
                <div className="permissionsList">
                    {
                        PERMISSIONS.map((field, index) => {
                            return(
                                <label className="permissions" onClick={()=> this.onChangeState(field.value)}
                                       key={field.value} id={selectedPermission == field.value ? "myPermissionSelected" : ""}>
                                       {formatWord(field.label)}
                                </label>
                            )
                        }
                        )
                    }
                </div>
            </div>

        )

    }

}

export default Permissions;