import axios from 'axios'
import {config, mailAlreadyUsed, sessionExpired, logOut, customerDoesNotExist, downloadFile} from './Utils'
import storage from 'local-storage-fallback'

export function uploadExpirations(data) {
  let token = storage.getItem('token')
  return axios.post(config.API_URL + `admin/expiration?token=${token}`, data)
}

export function uploadOficialized(data) {
  let token = storage.getItem('token')
  return axios.post(config.API_URL + `admin/oficialized?token=${token}`, data)
}

export function getCSVFile() {
  let token = storage.getItem('token')
  return axios.get(config.API_URL + `admin/expiration?token=${token}`)
}

export function getUsers() {
  let token = storage.getItem('token')
  return axios.get(config.API_URL + `admin/user?token=${token}`)
}

export function getUsersInfo(email) {
  let token = storage.getItem('token')
  return axios.get(config.API_URL + `admin/user/info?email=${email}&token=${token}`)
}

export function getCompanies() {
  let token = storage.getItem('token')
  return axios.get(config.API_URL + `admin/user/companies?token=${token}`)
}

export function getUsersExcel(name, mail, phone, cuit, user_type, date, comex_companies, eve_companies) {
  let token = storage.getItem('token')
  const filename = 'usuarios.xlsx'
  axios({
    method: 'GET',
    url: `${config.API_URL}admin/user/excel?token=${token}&name=${name}&mail=${mail}&phone=${phone}&cuit=${cuit}&user_type=${user_type}&date=${date}&comex=${comex_companies}&eve=${eve_companies}`,
    responseType: 'blob',
    headers: {'Content-Type': 'application/vnd.ms-excel'}
  }).then(function (response) {
    const blob = new Blob(
      [response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'})
    const aEle = document.createElement('a')     // Create a label
    const href = window.URL.createObjectURL(blob)       // Create downloaded link
    aEle.href = href
    aEle.download = filename  // File name after download
    document.body.appendChild(aEle)
    aEle.click()     // Click to download
    document.body.removeChild(aEle) // Download complete remove element
    window.URL.revokeObjectURL(href) // Release blob object
  })
}

export function postUser(newUser) {
  let token = storage.getItem('token')
  return axios.post(config.API_URL + `admin/user?token=${token}`, newUser)
}

export function putUser(email, newUser) {
  let token = storage.getItem('token')
  return axios.put(config.API_URL + `admin/user/${email}?token=${token}`, newUser)
}

export function deleteUser(email) {
  let token = storage.getItem('token')
  return axios.delete(config.API_URL + `admin/user/${email}?token=${token}`)
}

export function logIn(token) {
  return axios.post(config.API_URL + `auth`, {token})
}

export function resetPassword(email) {
  let token = storage.getItem('token')
  return axios.get(config.API_URL + `auth/accountant/reset?token=${token}&user=${email}`)
}

export function onRequestError(error) {
  const contact = 'Contacte a nico@brosoft.com.ar'

  if (error.response) {

    let errMsg

    if (error.response.status === 409) {
      errMsg = error.response.data
    } else if (error.response.status === 400) {
      errMsg = error.response.data
    } else if (error.response.status === 404) {
      errMsg = customerDoesNotExist
    } else if (error.response.status === 500) {
      errMsg = error.message +
        `\n` + error.response.data +
        `\n${contact}`
    } else if (error.response.status === 401) {
      logOut()
      errMsg = sessionExpired
    } else {
      errMsg = error.message +
        `\n${contact}`
    }

    alert(`Ha ocurrido un error.
          Error: ${error.response.status}
          ${errMsg}`)

  } else if (error.request) {

    alert(`Ha ocurrido un error.
          Error: the request was made but not response was received.
          ${contact}`)

  } else {

    alert(`Ha ocurrido un error.
          Error: ${error.message}
          ${contact}`)

  }
}