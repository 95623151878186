import React, {Component} from "react";
import {COMEX, CONTABLE, TYPE_COMEX, TYPE_CONTABLE, TYPE_SIDOM} from './Constants'

class Form extends Component {


    constructor(props) {
        super(props);
    }

    render() {
        const {handleInputChange, handleDropdownChange, formValues, selectedUser,errors,isNewUser,passwordUpdated,confirmPasswordEdit} = this.props;
        return (
            <div>
                <div id="name">
                    <nav className="level is-marginless">
                        <div className="level-left">
                            <label style={{marginRight: '10px', marginBottom: '6px'}}
                                   className="label is-small is-pulled-left separate-inputs">
                                Nombre
                            </label>
                        </div>
                        <div className="level-right is-paddingless">
                            <label className="help is-danger formLoginErrors" hidden={errors.name === ""}>{errors.name}</label>
                        </div>
                    </nav>
                    <div className="control has-icons-left">
                        <input className={errors.name !== "" ? "input is-danger" : "input is-small"}
                               type="text" placeholder="Nombre" name="name"
                               value={formValues.name} onChange={handleInputChange} tabIndex={0}/>
                               <span className="icon is-small is-left"><i className="fas fa-user"/></span>
                    </div>
                </div>

                <div id="mail">
                    <nav className="level is-marginless">
                        <div className="level-left">
                            <label style={{marginRight: '10px', marginBottom: '6px'}}
                                   className="label is-small is-pulled-left separate-inputs">
                                E-mail
                            </label>
                        </div>
                        <div className="level-right is-paddingless">
                            <label className="help is-danger formLoginErrors"
                                   hidden={errors.email === ""}>{errors.email}</label>
                        </div>
                    </nav>

                    <div id="email" className="control has-icons-left">
                        <input className={errors.email !== "" ? "input is-danger" : "input is-small"}
                               type="email"
                               disabled={selectedUser !== null}
                               placeholder="E-mail" name="email"
                               value={formValues.email} onChange={handleInputChange}/>
                        <span className="icon is-small is-left"><i className="fas fa-envelope"/></span>
                    </div>
                </div>


                {formValues.type === 0 ? (
                    <div id="Phone">
                        <nav className="level is-marginless">
                            <div className="level-left">
                                <label style={{marginRight: '10px', marginBottom: '6px'}}
                                       className="label is-small is-pulled-left separate-inputs">
                                    Teléfono
                                </label>
                            </div>
                            <div className="level-right is-paddingless">
                                <label className="help is-danger formLoginErrors"
                                       hidden={errors.phone === ""}>{errors.phone}</label>
                            </div>
                        </nav>
                        <div id="phone" className="control has-icons-left">
                            <input className={errors.phone !== "" ? "input is-small" : "input is-small"}
                                   type="text"
                                   placeholder="Telefono" name="phone"
                                   value={formValues.phone} onChange={handleInputChange}/>
                            <span className="icon is-small is-left"><i className="fas fa-phone"/></span>
                        </div>
                    </div>
                ) : (<div></div>)
                }

                <div id="cuit">
                    <nav className="level is-marginless">
                        <div className="level-left">
                            <label style={{marginRight: '10px', marginBottom: '6px'}}
                                   className="label  is-small is-pulled-left separate-inputs">CUIT</label>
                        </div>
                        <div className="level-right is-paddingless">
                            <label className="help is-danger formLoginErrors" hidden={errors.cuit === ""}>{errors.cuit}</label>
                        </div>
                    </nav>
                    <div className="control has-icons-left">
                        <input className={errors.cuit !== "" ? "input is-danger" : "input is-small"}
                               type="text" placeholder="CUIT"
                               name="cuit" id="cuit"
                               value={formValues.cuit} onChange={handleInputChange}
                               disabled={selectedUser? selectedUser.cuit:false}
                        />
                               <span className="icon is-small is-left"><i className="fas fa-id-card"/></span>
                    </div>
                </div>

                <div id="user_type">
                    <nav className="level is-marginless">
                        <div className="level-left">
                            <label style={{marginRight: '10px', marginBottom: '6px'}}
                                   className="label  is-small is-pulled-left separate-inputs">
                                Tipo de usuario
                            </label>
                        </div>
                        <div className="level-right is-paddingless">
                            <label className="help is-danger formLoginErrors" hidden={errors.user_type === ""}>{errors.user_type}</label>
                        </div>
                    </nav>
                    <div id="user_type" className="control has-icons-left">
                        <select name="user_type" id="user_type"
                                value={formValues.user_type}
                                style={{borderColor: '#dbdbdb', height: '30px', borderRadius: '2px'}}
                                onChange={handleDropdownChange}>
                            <option value={TYPE_CONTABLE}>Contable</option>
                            <option value={TYPE_COMEX}>COMEX</option>
                            <option value={TYPE_SIDOM}>SIDOM</option>
                        </select>
                    </div>
                </div>

                {!isNewUser && <div id="email_notification">
                    <nav className="level is-marginless">
                        <div className="level-left">
                            <label style={{marginRight: '10px', marginBottom: '6px'}}
                                   className="label is-small is-pulled-left separate-inputs">
                                Tipo de notificacion E-mail
                            </label>
                        </div>
                        <div className="level-right is-paddingless">
                            <label className="help is-danger formLoginErrors"
                                   hidden={errors.email === ''}>{errors.email}</label>
                        </div>
                    </nav>

                    <div id="email_notification" className="control has-icons-left">
                        <select name="email_notification" id="email_notification"
                                value={formValues.email_notification}
                                style={{borderColor: '#dbdbdb', height: '30px', borderRadius: '2px'}}
                                onChange={handleDropdownChange}>
                            <option value={CONTABLE}>Contable</option>
                            <option value={COMEX}>COMEX</option>
                            <option value="">No enviar</option>
                        </select>
                    </div>
                </div>}

                {/*Campo contraseña ocultado temporalmente hasta que se pueda mostrar
                en el campo la contraseña encriptada
                TODO: Descomentar cuando este implementada la funcionalidad de encriptacion*/}
                {/*{isNewUser && formValues.type !=1 &&*/}

                {/*<div id="password">*/}
                {/*    <nav className="level is-marginless">*/}
                {/*        <div className="level-left">*/}
                {/*            <label style={{marginRight: '10px', marginBottom: '6px'}}*/}
                {/*                   className="label is-pulled-left separate-inputs">*/}
                {/*                Contraseña*/}
                {/*            </label>*/}
                {/*            {passwordUpdated &&*/}
                {/*            (*/}
                {/*                <p>Enviado*/}
                {/*                    <span className="icon is-small">*/}
                {/*                        <i className="fas fa-check-circle"/>*/}
                {/*                    </span>*/}
                {/*                </p>)*/}
                {/*            }*/}
                {/*            {!passwordUpdated &&*/}
                {/*            <button onClick={confirmPasswordEdit}*/}
                {/*                    className={formValues.type !== 0 || selectedUser === null ?*/}
                {/*                        "help is-hidden anchor-button" : "help anchor-button"}>*/}
                {/*                Reset*/}
                {/*            </button>*/}
                {/*            }*/}
                {/*        </div>*/}
                {/*    </nav>*/}
                {/*</div>*/}
                {/*}*/}
            </div>
        )
    }
}


export default Form;