import React, {Component} from "react";
import CompaniesTable from "./CompaniesTable";
import {COMEX_COMPANIES} from "./Constants"

class CompaniesTab extends Component {


    constructor(props) {
        super(props);
    }

    render() {
        const {companies,editCompany,deleteCompany,addCompany,type,onDeleteCompanyCuit,onAddCompanyCuit,onEditCompanyCuit} = this.props;
        return (
                <div className="companyContainer">
                    <div className="field is-horizontal centered">
                         <div className="tags has-addons is-marginless">
                            <span className="tag is-info is-so-small">Empresas</span>
                            <span className="tag is-black is-so-small">{companies.length}</span>
                        </div>
                        <div className="addCompany">
                        <button onClick={() => addCompany(type)} className="button is-danger">
                                <span>Nueva Empresa</span>
                                <span className="icon is-small">
                                    <i className="fas fas fa-plus" style={{cursor: 'pointer'}}/>
                                </span>
                        </button>
                    </div>
                    </div>
                    <CompaniesTable
                        companies = {companies}
                        editCompany = {editCompany}
                        deleteCompany = {deleteCompany}
                        type={type}
                        onEditCompanyCuit={onEditCompanyCuit}
                        onAddCompanyCuit={onAddCompanyCuit}
                        onDeleteCompanyCuit={onDeleteCompanyCuit}
                    />
                </div>
            )
    }

}


export default CompaniesTab;