import React, {Component} from 'react';
import {
    cuitIndexFamily,
    formatName, indexByCuit, invalidCUIT, invalidEmail, invalidPhoneNumber,
    isCuitValid, mustCompleteField, nameTooLarge, validateEmail
} from "./Utils";
import {confirmAlert} from "react-confirm-alert";
import EditCompany from "./EditCompany";
import {getUsersInfo, onRequestError, resetPassword} from './Services'
import {COMEX_COMPANIES, CONTABLE, TYPE_CONTABLE, ATTACHMENT_SOURCE} from './UserFormColumns/Constants'
import FirstColumn from "./UserFormColumns/FirstColumn";
import {USER_SETTINGS} from "./UserFormColumns/Constants";
import SecondColumn from "./UserFormColumns/SecondColumn";
import ThirdColumn from "./UserFormColumns/ThirdColumn";
import {saveCuitInCompany,sortCompaniesByName,deleteCuitInCompany} from "./Utils"
import {FIRST_LEVEL_CUIT, SECOND_LEVEL_CUIT} from "./UserFormColumns/utils";
import {isEmpty} from 'lodash.isempty'
import Loading from './Loading'

class UserForm extends Component {

    constructor(props) {
        super(props);

        this.state = {

            selectedUser: null,

            formValues: {name: "", type: 1, email: "", phone: null, cuit: "",
                comex_companies:[], eve_companies:[], permissions:{}, powerbi:"",
                user_type: TYPE_CONTABLE, attachment_source:ATTACHMENT_SOURCE.NONE.value},

            errors: {name: "", email:"", phone:"", cuit:"", companies:"", powerbi:"",
            email_notification: "", user_type: ""},

            isButtonLoading: {delete: false, save: false},

            isNewUser: false,

            permissionsList:false,

            userForm:true,

            selectedPermission:"eventanilla",
            loading:false,

        }
    }

    handleDropdownChange = event => {
        let {formValues, errors} = this.state;
        formValues[event.target.name] = event.target.value;
        errors[event.target.name] = "";
        this.setState({formValues: formValues});
    }

    handleInputChange = event => {
        const {setUserModified} = this.props;
        let {formValues, errors} = this.state;
        formValues[event.target.name] = event.target.value;
        errors[event.target.name] = "";
        setUserModified();
        this.setState({formValues: formValues});
    };

    deleteCompany = (company,type) => {
        const {formValues} = this.state;
        if (type === COMEX_COMPANIES){
            const index = formValues.comex_companies.findIndex(x => x.name.toLowerCase() === company.name.toLowerCase()
            && x.cuit === company.cuit);
            formValues.comex_companies.splice(index, 1);
        }else{
            const index = formValues.eve_companies.findIndex(x => x.name.toLowerCase() === company.name.toLowerCase()
            && x.cuit === company.cuit);
            formValues.eve_companies.splice(index, 1);
        }
        const {setUserModified} = this.props;
        setUserModified();
        this.setState({formValues: formValues});
    };

    getCompany(company,type){
        if (type === COMEX_COMPANIES){
            return  {
                name: formatName(company.name),
                cuit: company.cuit? company.cuit.replace(/-/g, ""): "",
                cert: company.cert,
                user_type: company.user_type,
                cuits:company.cuits,
            };
        }else{
            return  {
                name: formatName(company.name),
                cuit: company.cuit? company.cuit.replace(/-/g, ""): "",
            };
        }
    }

    getCompanyList(type){
         if (type === COMEX_COMPANIES){
             return "comex_companies"
         } else {
             return "eve_companies"}
    }

    onSaveCompany = (editCompanyValues,type) => {
        let {formValues} = this.state;
        const {setUserModified} = this.props;
        const index = editCompanyValues.index;
        const newCompany = this.getCompany(editCompanyValues,type);
        const CompanyList = this.getCompanyList(type);
        if (index === -1) {
            formValues[CompanyList].push(newCompany);
        } else {
            formValues[CompanyList][index] = newCompany
        }

        sortCompaniesByName(formValues[CompanyList]);
        setUserModified();
        this.setState({formValues: formValues});
    };


    editCompany = (company,type) => {
        const companyList = this.getCompanyList(type);
        const companies = this.state.formValues[companyList];
        const {enableEditCompanyForm, disableEditCompanyForm} = this.props;

        const index = companies.findIndex(x => x.name.toLowerCase() === company.name.toLowerCase()
            && x.cuit === company.cuit );

        let form = <EditCompany company={company} index={index} type={type}
                                disableEditCompanyForm={disableEditCompanyForm}
                                onSaveCompany={this.onSaveCompany} operationsForCuit={company.user_type ? company.user_type.length > 0 : false}/>;

        enableEditCompanyForm(form);

    };

    addCompany = (type) => {

        const {enableEditCompanyForm, disableEditCompanyForm} = this.props;

        const newCompany = type === COMEX_COMPANIES ? {name:"",cuit:"",cert:"", user_type:"", cuits:[]} : {name:"",cuit:""}

        let form = <EditCompany company={newCompany} index={-1} type={type}
                                disableEditCompanyForm={disableEditCompanyForm}
                                onSaveCompany={this.onSaveCompany} operationsForCuit={false}/>;
        enableEditCompanyForm(form);

    };


    validateInputs = () => {

        let {formValues,selectedUser} = this.state;
        let cuit = formValues.cuit.replace(/-/g, "");

        let errors = {
            name: "",
            email: "",
            phone: "",
            cuit: "",
            companies: "",
            powerbi:"",
            user_type: "",
            email_notification: "",
        };

        if (formValues.user_type == null) {
            errors.user_type = mustCompleteField;
            this.columnClicked("userForm");
        }

        if (formValues.email_notification == null) {
            errors.email_notification = mustCompleteField;
            this.columnClicked("userForm");
        }

        if (!formValues.powerbi.length > 0 && formValues.permissions["reports"]) {
            errors.powerbi = mustCompleteField;
            this.columnClicked("permissionsList");
            this.handlePermissionSelected("reports");
            return errors;
        }

        if (!formValues.name.length > 0) {
            errors.name = mustCompleteField;
            this.columnClicked("userForm");
        }

        if (formValues.name.length > 40) {
            errors.name = nameTooLarge;
            this.columnClicked("userForm");
        }

        if (!formValues.email.length > 0) {
            errors.email = mustCompleteField;
            this.columnClicked("userForm");
        } else if (!validateEmail(formValues.email)) {
            errors.email = invalidEmail;
            this.columnClicked("userForm");
        }

        if (cuit.length === 0){
            if ((cuit.length !== 11  || !isCuitValid(cuit)) && !selectedUser ){
                errors.cuit = invalidCUIT;
                this.columnClicked("userForm");
            }
        }

        return errors;

    };

    onEnterSaveUser = (event) => {
        if (event.keyCode === 13) {
            this.saveUser();
        }
    }

    saveUser = () => {
        let {formValues} = this.state;
        const {setButtonLoading} = this.props;
        setButtonLoading("save", true);

        let inputErrors = this.validateInputs();
        let hasErrors = Object.keys(inputErrors).some(x => inputErrors[x]);

        if (hasErrors) {
            this.setState({errors: inputErrors});
            setButtonLoading("save", false);
            return;
        }

        let newUser;

        newUser = {
                name: formatName(formValues.name.trim()),
                type: formValues.type,
                email: formValues.email.trim(),
                phone: formValues.phone,
                cuit: formValues.cuit.replace(/-/g, ""),
                amount_comex_companies: formValues.comex_companies.length,
                amount_eve_companies: formValues.eve_companies.length,
                comex_companies:formValues.comex_companies,
                eve_companies:formValues.eve_companies,
                permissions: formValues.permissions,
                user_type: formValues.user_type,
                email_notification: formValues.email_notification,
                powerbi: formValues.powerbi.length === 0 ? null : formValues.powerbi,
                attachment_source: formValues.attachment_source
        };

        const {onSaveUser} = this.props;
        onSaveUser(newUser);

        this.setState({errors: inputErrors});
    };

    confirmPasswordEdit = () => {

        let {selectedUser} = this.state;
        confirmAlert({
            message: 'Se le enviara un correo al usuario con la nueva contraseña provisoria e indicaciones para que la modifique, la actual dejara de funcionar. ' +
                '¿Está seguro?',
            buttons: [
                {
                    label: 'Si',
                    onClick: () => {
                        //call server
                        resetPassword(selectedUser.email)
                        this.setState({passwordUpdated: true});
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                    }
                }
            ]
        });

    };


    componentWillReceiveProps(nextProps, nextContext) {

        let {newSelectedUser, isButtonLoading} = nextProps;

        this.setState({isButtonLoading: isButtonLoading});

        let {selectedUser} = this.state;


        if (newSelectedUser !== selectedUser) {

            this.setState({selectedUser: newSelectedUser});
            if (newSelectedUser == null) {
                this.setState({
                    formValues: {
                        name: "",
                        password: "",
                        cuit: "",
                        companies: [],
                        comex_companies:[],
                        eve_companies:[],
                        permissions: {},
                        powerbi: "",
                        user_type: TYPE_CONTABLE,
                        email_notification: CONTABLE,
                        email: "",
                        phone: "",
                        type: 1,
                        attachment_source: ATTACHMENT_SOURCE.NONE.value
                    },
                    isNewUser: false,
                });
            } else {
                this.setState({formValues: {
                        name: newSelectedUser.name,
                        email: newSelectedUser.email,
                        cuit: newSelectedUser.cuit ? newSelectedUser.cuit : "",
                        password: newSelectedUser.password == null ? "" : newSelectedUser.password,
                        user_type: newSelectedUser.user_type ? newSelectedUser.user_type : TYPE_CONTABLE,
                        email_notification: newSelectedUser.email_notification ? newSelectedUser.email_notification : CONTABLE,
                        phone: newSelectedUser.phone,
                        type: newSelectedUser.type,
                        attachment_source: newSelectedUser.attachment_source ? newSelectedUser.attachment_source : ATTACHMENT_SOURCE.NONE.value
                    },
                    loading:true
                    }, () => {
                    const {formValues, selectedUser} = this.state;
                    getUsersInfo(selectedUser.email).then(response => {
                        this.setState({
                            formValues: {...formValues,
                                comex_companies:response.data.comex_companies.sort((a, b) => a.name.localeCompare(b.name)),
                                eve_companies:response.data.eve_companies.sort((a, b) => a.name.localeCompare(b.name)),
                                permissions: response.data.permissions,
                                powerbi: response.data.powerbi !== null ? response.data.powerbi : "",
                                attachment_source: response.data.attachment_source,
                            },
                            loading:false

                        });
                    })
                      .catch(
                        error => {
                            onRequestError(error)
                        }
                      );
                })
                this.setState({passwordUpdated: false, isNewUser: true, permissionsList: false,
                userForm:true, selectedPermission:"eventanilla"});
            }

            this.setState({
                errors: {
                    name: "",
                    email: "",
                    phone: null,
                    cuit: "",
                    password: "",
                    companies: "",
                    powerbi:"",
                }
            });
        }
    };

    handlePowerBiLink = (link) =>{
         let {formValues} = this.state;
         formValues.powerbi = link;
         this.setState({formValues: formValues});
    };

     onChangePermissionState = (permission) =>{
        let {formValues,errors} = this.state;
        if (permission === "reports"){errors.powerbi =""}
        formValues.permissions[permission] = !this.state.formValues.permissions[permission];
        this.setState({formValues: formValues});
     };


     columnClicked = (name) =>{
         if (name === "userForm"){this.setState({selectedPermission:"eventanilla"})}
         USER_SETTINGS.forEach(setting => this.setState({[setting.value]:false}));
         this.setState({[name]:true})
    };

     handlePermissionSelected = (permission) =>{
         this.setState({ selectedPermission: permission});
     };

     onSaveCompanyCuit = (cuitFamily,level,indexFamily,newCuit) => {
        let {formValues} = this.state;
        const {setUserModified} = this.props;
        saveCuitInCompany(formValues.comex_companies,cuitFamily,level,indexFamily,newCuit);
        setUserModified();
        this.setState({formValues: formValues});
    };

     onEditCompanyCuit = (cuitFamily,level) => {
        const {enableEditCompanyForm, disableEditCompanyForm} = this.props;
        const indexFamily = cuitIndexFamily(this.state.formValues.comex_companies,cuitFamily,level);
        let form = <EditCompany company={cuitFamily} level={level}
                                disableEditCompanyForm={disableEditCompanyForm}
                                onSaveCompany={this.onSaveCompanyCuit} indexFamily={indexFamily}
        />;

        enableEditCompanyForm(form);
    };

    onAddCompanyCuit = (cuitFamily,level) => {
        const {enableEditCompanyForm, disableEditCompanyForm} = this.props;
        let auxLevel;
        switch (level) {
            case FIRST_LEVEL_CUIT :
                const newSLCompany = {name:"",cuit:"",cuits:[]};
                cuitFamily.secondLevel = newSLCompany;
                auxLevel = SECOND_LEVEL_CUIT;
                break;
            default:
                const newFLCompany = {name:"",cuit:"",cuits:[],user_type:null,cert:null};
                cuitFamily.firstLevel = newFLCompany;
                auxLevel = FIRST_LEVEL_CUIT;
                break;
        }

        const indexFamily = cuitIndexFamily(this.state.formValues.comex_companies,cuitFamily,auxLevel);

        let form = <EditCompany company={cuitFamily} index={-1} level={auxLevel}
                                disableEditCompanyForm={disableEditCompanyForm}
                                onSaveCompany={this.onSaveCompanyCuit} indexFamily={indexFamily}/>;

        enableEditCompanyForm(form);

    };

    onDeleteCompanyCuit = (cuitFamily,level) => {
        const {formValues} = this.state;
        const {setUserModified} = this.props;
        deleteCuitInCompany(formValues.comex_companies,cuitFamily,level)
        setUserModified();
        this.setState({formValues: formValues});
    };

    render() {

        let {formValues, errors, selectedUser, isButtonLoading, passwordUpdated, isNewUser,permissionsList,
        userForm,selectedPermission, loading} = this.state;
        return (
            <div className="box is-paddingless userFormModal" tabIndex="0" onKeyDown={this.onEnterSaveUser}>
                <div className="columns is-marginless">
                    <div className="column is-one-fifth" id="columnCentered">
                        <FirstColumn columnClicked={this.columnClicked}  permissionsList={permissionsList} userForm={userForm}/>
                        <div className="saveButton">
                             <div className="buttons is-centered">
                                 <button onClick={this.saveUser}
                                         className={isButtonLoading.save
                                             ? "button is-info is-loading userFormSaveButton"
                                             : "button is-info userFormSaveButton"}>
                                     <span>Guardar</span>
                                     <span className="icon is-small"><i className="fas fa-check"/></span>
                                 </button>
                             </div>
                        </div>
                    </div>
                    <div className={userForm ? "column is-four-fifths" : "column is-one-fifth"}>
                        <SecondColumn handleInputChange={this.handleInputChange} formValues={formValues}
                                      handleDropdownChange={this.handleDropdownChange}
                                      selectedUser={selectedUser} errors={errors} isNewUser={isNewUser}
                                      passwordUpdated={passwordUpdated} confirmPasswordEdit={this.confirmPasswordEdit}
                                      permissionsList={permissionsList} userForm={userForm}
                                      handlePermissionSelected = {this.handlePermissionSelected}
                                      selectedPermission={selectedPermission}
                        />
                    </div>
                    {selectedPermission.length > 0 && !userForm &&
                    <div className="column is-three-fifths">
                         <ThirdColumn selectedPermission={selectedPermission}
                                      onChangePermissionState={this.onChangePermissionState}
                                      permissions={formValues.permissions} formValues={formValues}
                                      editCompany={this.editCompany} deleteCompany={this.deleteCompany}
                                      addCompany={this.addCompany} handlePowerBiLink={this.handlePowerBiLink}
                                      handleDropdownChange={this.handleDropdownChange} errors={errors}
                                      onEditCompanyCuit={this.onEditCompanyCuit} onAddCompanyCuit={this.onAddCompanyCuit}
                                      onDeleteCompanyCuit={this.onDeleteCompanyCuit}
                         />
                    </div>
                    }
                </div>
                {loading && <Loading/>}

            </div>

        )
    }
}

export default UserForm;