import React, {Component} from 'react';
import history from "./History";

class Navigation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: null,
        }
    }

    componentWillMount() {

        let {activeTab} = this.props;
        this.setState({activeTab: activeTab})

    }

    onUsersClick = () => {
        const {discardChanges} = this.props;
        this.switchToUsersView();
    };

    switchToUsersView = () => {
        history.push("/dashboard");
        this.setState({activeTab: "users"});
    };

    onExpirationsClick = () => {
        const {discardChanges} = this.props;
        if (discardChanges) {
            discardChanges(this.switchToExpirationsView)
        } else {
            this.switchToExpirationsView();
        }
    };

    switchToExpirationsView = () => {
        history.push("/expirations");
        this.setState({activeTab: "expirations"});
    };

    onOficializedClick = () => {
        const {discardChanges} = this.props;
        if (discardChanges) {
            discardChanges(this.switchToOfficializedView)
        } else {
            this.switchToOfficializedView();
        }

    };

    switchToOfficializedView = () => {
        history.push("/oficialized");
        this.setState({activeTab: "oficialized"});
    };

    render() {

        const {activeTab} = this.state;

        return (

            <div className="tabs" style={{marginBottom: 0}}>
                <ul>
                    <li id="users"
                        onClick={this.onUsersClick}
                        className={activeTab === "users" ? "is-active" : ""}>
                        <a>Usuarios</a>
                    </li>
                    <li id="expirations"
                        onClick={this.onExpirationsClick}
                        className={activeTab === "expirations" ? "is-active" : ""}>
                        <a>Vencimientos</a>
                    </li>
                    <li id="oficializados"
                        onClick={this.onOficializedClick}
                        className={activeTab === "oficialized" ? "is-active" : ""}>
                        <a>Oficializados</a>
                    </li>
                </ul>
            </div>

        )

    }

}

export default Navigation;