import React, {Component} from 'react';
import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {onRequestError, postUser, putUser} from './Services';
import './App.css';
import UserForm from "./UserForm";
import {userHasBeenModified} from "./Utils";


class UserInfo extends Component {


    constructor(props) {
        super(props);

        this.state = {

            userModified: false,

            selectedUser: null,

            popUpHidden: true,

            userList: [],

            isButtonLoading: {
                delete: false,
                save: false,
            },

            editCompanyForm: null,

            inputValue: "",

        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        let {newSelectedUser, newUserList, popUpHidden} = nextProps;
        this.setState({selectedUser: newSelectedUser});
        this.setState({userList: newUserList});
        this.setState({popUpHidden: popUpHidden});
    }

    resetFields = () => {
        this.discardChanges(this.emptyFields)
    };

    emptyFields = () => {
        this.setState({selectedUser: null});
        this.setState({editPassword: true});
    };

    setButtonLoading = (button, value) => {
        let {isButtonLoading} = this.state;
        isButtonLoading[button] = value;
        this.setState({isButtonLoading: isButtonLoading});
    };

    onSaveUser = (newUser) => {
        let {selectedUser, userList} = this.state;

        newUser.comex_companies.forEach((company) => {
                company.name = company.name.trim()
            })

        newUser.eve_companies.forEach((company) => {
                company.name = company.name.trim()
            })

        if (selectedUser === null) {
            postUser(newUser)
                .then(
                    () => {
                        this.updateListAfterSavingUser(userList, newUser);
                        this.resetSelectedUser();
                        this.closePopUp();
                    }
                )
                .catch(
                    error => {
                        onRequestError(error);
                        this.setButtonLoading("save", false);
                    }
                );
        } else {

            putUser(selectedUser.email, newUser)
                .then(
                    () => {
                        let userIndex = userList.findIndex(x => x === selectedUser);
                        userList.splice(userIndex, 1);
                        this.updateListAfterSavingUser(userList, newUser);
                        this.resetSelectedUser();
                        this.closePopUp();
                    }
                )
                .catch(
                    error => {
                        onRequestError(error);
                        this.setButtonLoading("save", false);
                    }
                );
        }

        this.setState({userModified: false});
    };


    sortUserListByName = userList => {
        userList.sort((a, b) => a.name.localeCompare(b.name));
    };

    updateListAfterSavingUser = (userList, newUser) => {
        userList.push(newUser);
        this.setState({userList: userList});
        this.sortUserListByName(userList);
        this.setState({editPassword: false});
        this.setButtonLoading("save", false);
        const {onUpdateUserList} = this.props;
        onUpdateUserList(userList);
    };

    resetSelectedUser = () => {
        this.setState({selectedUser: null});
    };

    discardChanges = (yesFunction, param) => {
        let {userModified} = this.state;
        if (userModified) {
            confirmAlert({
                message: userHasBeenModified,
                buttons: [
                    {
                        label: 'Si',
                        onClick: () => {
                            this.setState({userModified: false});
                            if (param) {
                                yesFunction(param);
                            } else {
                                yesFunction();
                            }
                        }
                    },
                    {
                        label: 'No',
                        onClick: () => {
                            return false;
                        }
                    }
                ]
            });
        } else {
            if (param) {
                yesFunction(param);
            } else {
                yesFunction();
            }
        }
    };

    setUserModified = () => {
        this.setState({userModified: true});
    };

    disableEditCompanyForm = () => {
        this.setState({editCompanyForm: null});
    };

    enableEditCompanyForm = form => {
        this.setState({editCompanyForm: form});
    };

    closePopUp = () => {
        const {onClosePopUp,getUsers} = this.props;
        onClosePopUp();
        // getUsers();
    };

    scapePopUp = (event) => {
        if (event.keyCode === 27 && this.state.editCompanyForm == null) {
            event.preventDefault();
            this.closePopUp();
        } else if (event.keyCode === 27 && this.state.editCompanyForm != null) {
            this.disableEditCompanyForm();
        }
    }


    scapeCompaniesPopUp = (event) => {
        if (event.keyCode === 27) {
            this.setState({editCompanyForm: null})
        }
    }

    render() {

        const {
            selectedUser, isButtonLoading, editCompanyForm, popUpHidden
        } = this.state;

        return (
            <div className="userInfoDashboard">
                <div className={popUpHidden === true ? "modal" : "modal is-active"} onKeyDown={this.scapePopUp}
                     tabIndex="0">
                    <div className="modal-background"/>
                    <div className="modal-content user-form-modal-content">
                        <UserForm newSelectedUser={selectedUser}
                                  onSaveUser={this.onSaveUser}
                                  setButtonLoading={this.setButtonLoading}
                                  isButtonLoading={isButtonLoading}
                                  enableEditCompanyForm={this.enableEditCompanyForm}
                                  disableEditCompanyForm={this.disableEditCompanyForm}
                                  setUserModified={this.setUserModified}
                                  closePopUp={this.closePopUp}
                        />
                    </div>
                    <button onClick={this.closePopUp}
                            className="modal-close is-large" aria-label="close"/>
                </div>
                <div className={editCompanyForm == null ? "modal" : "modal is-active"}
                     onKeyDown={this.scapeCompaniesPopUp}>
                    <div className="modal-background"/>
                    <div className="modal-content">
                        {editCompanyForm}
                    </div>
                    <button onClick={this.disableEditCompanyForm}
                            className="modal-close is-large" aria-label="close"/>
                </div>
            </div>
        )
    }

}

export default UserInfo;