import React, {Component} from 'react';
import matchSorter from "match-sorter"
import moment from 'moment';
import ReactTable from "react-table";
import "react-table/react-table.css";
import {confirmAlert} from "react-confirm-alert";
import {deleteUser, onRequestError} from "./Services";
import {TYPE_COMEX, TYPE_CONTABLE, TYPE_SIDOM} from "./UserFormColumns/Constants";


class UserTable extends Component {

    constructor(props) {
        super(props);
        this.selectTable = [];
        this.state = {
            height: 0,
            selectedUser: null,
        };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.updateColumnFilters = this.updateColumnFilters.bind(this);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        this.props.makeSelectData(this.selectTable)
    }

    updateWindowDimensions() {
        this.setState({height: window.innerHeight - 110});
    }

    componentWillMount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    handleEdit(data) {
        this.findUserWithEmail(data.email);
        const {onOpenPopUp} = this.props;

        onOpenPopUp();
    }

    handleDelete(data) {
        this.findUserWithEmail(data.email);
        this.deleteUser();
    }

    findUserWithEmail(mail) {
        const userList = this.props.userList;
        const index = userList.findIndex(x => x.email === mail);
        const {onSelectUser} = this.props;
        onSelectUser(userList[index]);
    }

    deleteUser = () => {
        confirmAlert({
            title: 'Eliminar usuario',
            message: '¿Está seguro de que desea eliminar el usuario?',
            buttons: [
                {
                    label: 'Si',
                    onClick: () => {
                        deleteUser(this.props.selectedUser.email)
                            .then(
                                () => {
                                    let userList = this.props.userList;
                                    const index = userList.findIndex(x => x === this.props.selectedUser);
                                    userList.splice(index, 1);
                                    const {onDeleteUser} = this.props;
                                    onDeleteUser(userList);
                                }
                            )
                            .catch(
                                error => {
                                    onRequestError(error);
                                }
                            );
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                    }
                }
            ]
        });

    };

    customOptionsFilterMethod = (filter, row) => {
      if(!row[filter.id]) {
          return false
      }
      else{
       return row[filter.id].startsWith(filter.value)
      }
    };

    dateCustomOptionsFilterMethod = (filter, row) => {
      if (!row[filter.id]) {
          return false;
      } else {
          return moment(row[filter.id]).format("DD/MM/YYYY HH:mm").startsWith(filter.value);
      }
    };

    getAmountOfCompaniesOfUser = (userEmail, companyType) => {
        const userList = this.props.userList;
        for(const user of userList){
            if(user.email == userEmail) {
                return companyType == 'eve_companies' ? user.amount_eve_companies : user.amount_comex_companies
            }
        }
        return 0
    }

    getAmountOfFiscalKeys = (userEmail) => {
        const userList = this.props.userList;
        for(const user of userList){
            if(user.email == userEmail) {
                return user.fiscal_key;
            }
        }
        return 0
    }

    updateColumnFilters(column, cvalue){
        const {onFiltersChanged} = this.props
        /*Because how the react table works, when the last character of a column
          filter is cleared, the parameter value is undefined. So we capture from the object 'value'
          which is the column that was cleared it and remove the last character */
        if( column === undefined ) {
            onFiltersChanged(cvalue.id, "")
            return
        }
        const {id, value} = column
        onFiltersChanged(id,value)
    }


    render() {
        const {height} = this.state;
        return (
            <div className="reactTableDiv">
                <ReactTable
                    data={this.props.userList}
                    filterable
                    ref={(r) => {this.selectTable = r;}}
                    columns={[
                        {
                            columns: [
                                {
                                    Header: "Nombre",
                                    id: "name",
                                    accessor: "name",
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {keys: ["name"]}),
                                    filterAll: true
                                },
                                {
                                    Header: "E-Mail",
                                    id: "mail",
                                    accessor: "email",
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {keys: ["mail"]}),
                                    filterAll: true
                                },
                                {
                                    Header: "Telefono",
                                    id: "phone",
                                    accessor: "phone",
                                    filterMethod:  (filter, row) => this.customOptionsFilterMethod(filter, row),
                                },
                                {
                                    Header: "Cuit",
                                    accessor: "cuit",
                                    filterMethod: (filter, row) =>
                                        this.customOptionsFilterMethod(filter, row),
                                },
                                {
                                    Header: "Tipo",
                                    id: "user_type",
                                    accessor: "user_type",
                                    Cell: (props) => {
                                        if (props.original.user_type == TYPE_CONTABLE) {
                                            return "Contable"
                                        } else if (props.original.user_type == TYPE_COMEX) {
                                            return "COMEX"
                                        } else if (props.original.user_type == TYPE_SIDOM) {
                                            return "SIDOM"
                                        } else {
                                            return "N/A"
                                        }
                                    },
                                    filterMethod: (filter, row) => {
                                        if (filter.value === "all") {
                                            return true;
                                        }
                                        return row[filter.id] == filter.value
                                    },
                                    Filter: ({filter, onChange}) =>
                                        <select
                                            onChange={event => onChange(event.target.value)}
                                            style={{width: "100%"}}
                                            value={filter ? filter.value : "all"}
                                        >
                                            <option value="all">Todos</option>
                                            <option value={TYPE_CONTABLE}>Contable</option>
                                            <option value={TYPE_COMEX}>COMEX</option>
                                            <option value={TYPE_SIDOM}>SIDOM</option>
                                        </select>
                                },
                                {
                                    Header: "Fecha de ingreso",
                                    id: "date",
                                    accessor: "date",
                                    sortMethod: (a, b) => {
                                        return new Date(a).getTime() - new Date(b).getTime();
                                    },
                                    filterMethod:  (filter, row) => this.dateCustomOptionsFilterMethod(filter, row),
                                    Cell: (props) => props.original.date ? moment(props.original.date).format("DD/MM/YYYY HH:mm"):""
                                },
                                {
                                    Header: "Delegaciones Eve",
                                    id: "eve_companies",
                                    accessor: "eve_companies",
                                    Cell: (props) => {
                                        if (props.original.amount_eve_companies === 0) {
                                            return "X"
                                        } else {
                                            return "✓"
                                        }
                                    },
                                    filterMethod: (filter, row) => {
                                        if (filter.value === "all") {
                                            return true;
                                        }
                                        if (filter.value === "false") {
                                            return this.getAmountOfCompaniesOfUser(row.mail, filter.id) === 0;
                                        }
                                        return this.getAmountOfCompaniesOfUser(row.mail, filter.id) > 0;
                                    },
                                    Filter: ({filter, onChange}) =>
                                        <select
                                            onChange={event => onChange(event.target.value)}
                                            style={{width: "100%"}}
                                            value={filter ? filter.value : "all"}
                                        >
                                            <option value="all">Todos</option>
                                            <option value="true">✓</option>
                                            <option value="false">X</option>
                                        </select>
                                },
                                 {
                                    Header: "Comex Delegaciones",
                                    id: "comex_companies",
                                    accessor: "comex_companies",
                                    Cell: (props) => {
                                        if (props.original.amount_comex_companies === 0) {
                                            return "X"
                                        } else {
                                            return "✓"
                                        }
                                    },
                                    filterMethod: (filter, row) => {
                                        if (filter.value === "all") {
                                            return true;
                                        }
                                        if (filter.value === "false") {
                                            return this.getAmountOfCompaniesOfUser(row.mail, filter.id) === 0;
                                        }
                                        return this.getAmountOfCompaniesOfUser(row.mail, filter.id) > 0;
                                    },
                                    Filter: ({filter, onChange}) =>
                                        <select
                                            onChange={event => onChange(event.target.value)}
                                            style={{width: "100%"}}
                                            value={filter ? filter.value : "all"}
                                        >
                                            <option value="all">Todos</option>
                                            <option value="true">✓</option>
                                            <option value="false">X</option>
                                        </select>
                                },
                                {
                                    Header: "Clave Fiscal",
                                    id: "fiscal_key",
                                    accessor: "fiscal_key",
                                    Cell: (props) => {
                                        if (props.original.fiscal_key === 0) {
                                            return "X"
                                        } else {
                                            return "✓"
                                        }
                                    },
                                    filterMethod: (filter, row) => {
                                        if (filter.value === "all") {
                                            return true;
                                        }
                                        if (filter.value === "false") {
                                            return this.getAmountOfFiscalKeys(row.mail) === 0;
                                        }
                                        return this.getAmountOfFiscalKeys(row.mail) > 0;
                                    },
                                    Filter: ({filter, onChange}) =>
                                        <select
                                            onChange={event => onChange(event.target.value)}
                                            style={{width: "100%"}}
                                            value={filter ? filter.value : "all"}
                                        >
                                            <option value="all">Todos</option>
                                            <option value="true">✓</option>
                                            <option value="false">X</option>
                                        </select>
                                },
                                {
                                    Header: "",
                                    filterable: false,
                                    Cell: row => (
                                        <div>
                                            <button className="button is-outlined is-small"
                                                    onClick={() => this.handleEdit(row.original)}>Información
                                            </button>
                                            <button className="button is-outlined is-small"
                                                    onClick={() => this.handleDelete(row.original)}>Borrar
                                            </button>
                                        </div>
                                    )
                                }
                            ]
                        },
                    ]}
                    defaultPageSize={10}
                    onFilteredChange={(column, value) => {this.updateColumnFilters(column[column.length - 1], value)}}
                    // onFilteredChange={(column, value) => {console.log("filter changed"); console.log(column); console.log(value)}}
                    className="-striped -highlight"
                    style={{
                        height: height + 'px'
                    }}
                />
            </div>
        )
    }

}

export default UserTable;