import React, {Component} from 'react';
import {Redirect, Route, Router, Switch} from "react-router-dom";
import storage from 'local-storage-fallback';
import History from './History'
import Login from './Login'
import App from "./App";

const isLogged = () => {
  const token = storage.getItem('token');
  const logged = token !== null && token !== undefined  && token !== "";
  return logged;
};

const ProtectedRoute = ({component: Component, ...rest}) => (
  <Route {...rest} render={ props => {
    return (isLogged() === rest.authRequired) ?
      <Component {...props}/> :
      <Redirect to={{pathname: rest.notAuthRedirectTo, state: {from: props.location}}}/>;
  }} />
);

class Routes extends Component {
  render() {
    return (
      <Router history={History}>
        <Switch>
          <ProtectedRoute authRequired={false} notAuthRedirectTo='/' path='/login' component={Login} />
          <ProtectedRoute authRequired={true} notAuthRedirectTo='/login' path='/' component={App} />
        </Switch>
      </Router>
    );
  }
}

export default Routes;