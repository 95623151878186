import React, {Component} from 'react';
import './App.css';
import 'bulma/css/bulma.css';
import Expirations from './Expirations'
import Companies from './Companies'
import Reports from './Reports'
import {Route, Switch} from "react-router-dom";
import Oficialized from "./Oficialized";
import HomePage from "./HomePage";

class App extends Component {

  render() {
    return (
      <div className="App">
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route exact path="/dashboard" component={HomePage} />
          <Route exact path="/expirations" component={Expirations} />
          <Route exact path="/oficialized" component={Oficialized} />
          <Route exact path="/companies" component={Companies} />
          <Route exact path="/informes" component={Reports}/>
        </Switch>
      </div>
    );
  }

}

export default App;
