import React, {Component} from 'react';
import "react-table/react-table.css";
import Header from "./Header";
import {getUsers, getUsersExcel, onRequestError} from './Services'
import StatsList from "./StatsList";
import UserTable from "./UserTable";
import UserInfo from "./UserInfo";
import download from "downloadjs";
import _ from "lodash";
import moment from "moment";
import XLSX from 'xlsx';
import Loading from "./Loading"
import {TYPE_CONTABLE} from './UserFormColumns/Constants'


class HomePage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectList: null,
            userList: [],
            selectedUser: null,
            popUpHidden: true,
            loading:false,
            labels: {
                totalUsers: 0,
                eveUsers: 0,
                totalEveCompanies: 0,
                totalComexCompanies: 0,
            },
        };
        this.getUsers = this.getUsers.bind(this);
        this.updateFilteringValues = this.updateFilteringValues.bind(this);
    };

    componentWillMount() {
        this.getUsers();
    };

    getUsers() {
         this.setState({loading:true});
        getUsers()
            .then(
                response => {
                    let userList = response.data;
                    this.sortUserListByName(userList);
                    this.setState({userList: userList});
                    this.getStats();
                    this.setState({loading:false});
                }
            )
            .catch(
                error => {
                    onRequestError(error)
                    this.setState({loading:false});
                }
            );
    };

    sortUserListByName = userList => {
        userList.sort((a, b) => a.name.localeCompare(b.name));
    };

    getStats() {
        const eveUsers = this.state.userList.filter((user) => {
            return user.user_type === TYPE_CONTABLE
        });
        let auxUserList = this.state.userList;
        let totalEveCompanies = 0;
        let totalComexCompanies = 0;
        auxUserList.forEach(function (user) {
            totalEveCompanies += user.amount_eve_companies;
        });
        auxUserList.forEach(function (user) {
            totalComexCompanies += user.amount_comex_companies;
        });
        this.setState({
            labels: {
                totalUsers: this.state.userList.length,
                eveUsers: eveUsers.length,
                totalEveCompanies: totalEveCompanies,
                totalComexCompanies: totalComexCompanies,
            }
        });
    };

    makeSelectData = (selectTable) => {
        this.setState({selectList: selectTable});
    };

    onDeleteUser = (userList) => {
        this.setState({userList: userList});
        this.getStats()
    };

    onOpenPopUp = () => {
        this.setState({popUpHidden: false});
    };

    onClosePopUp = () => {
        this.setState({popUpHidden: true, selectedUser: null});
    };

    onSelectUser = (selectedUser) => {
        this.setState({selectedUser: selectedUser});
    };

    createNewUser = () => {
        this.setState({selectedUser: null});
        this.onOpenPopUp();
    };

    makeCsvValues = (value) => {
        const valueArray = value.split(",");
        if (valueArray[1]){
            return valueArray[0] + valueArray[1]
        } else {
            return value
        }
    };

    s2ab = (s) => {
        let buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
        let view = new Uint8Array(buf);  //create uint8array as viewer
        for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
        return buf;
    };

    // exportAccountants = () => {
    //     const {selectList} = this.state;
    //
    //     let wb = XLSX.utils.book_new();
    //     wb.Props = {
    //         Title: "Contadores",
    //         Subject: "Contadores",
    //         Author: "DASA Dashboard",
    //         CreatedDate: new Date()
    //     };
    //     wb.SheetNames.push("Contadores");
    //
    //     let ws_data = [];
    //     ws_data.push(["Id Contador","Contador","Cuit Contador","Mail","Telefono","Tipo","Fecha de Ingreso"]);
    //
    //
    //     _.each(selectList.getResolvedState().sortedData, user => {
    //         const date = moment(user._original.date).format("DD/MM/YYYY");
    //         const name = this.makeCsvValues(user.name);
    //         let type = user.type === 0 ? "Contador":"Despachante";
    //         ws_data.push([user.id, name, user.cuit, user.mail, user.phone || "", type, date]);
    //     });
    //
    //     wb.Sheets["Contadores"] = XLSX.utils.aoa_to_sheet(ws_data);
    //     let wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'binary'});
    //
    //
    //     download(this.s2ab(wbout), `contadores-${new Date().toISOString()}.xlsx`, 'application/octet-stream');
    // };
    //
    // exportClients = () => {
    //     const {selectList} = this.state;
    //
    //     let wb = XLSX.utils.book_new();
    //     wb.Props = {
    //         Title: "Clientes",
    //         Subject: "Clientes",
    //         Author: "DASA Dashboard",
    //         CreatedDate: new Date()
    //     };
    //     wb.SheetNames.push("Clientes");
    //
    //     let ws_data = [];
    //     ws_data.push(["Id Contador","Cliente","Cuit Cliente"]);
    //
    //
    //     _.each(selectList.getResolvedState().sortedData, user => {
    //         _.each(user._original.companies, company => {
    //            ws_data.push([user.id, company.name.replace(",", "/"), company.cuit]);
    //         });
    //     });
    //
    //     wb.Sheets["Clientes"] = XLSX.utils.aoa_to_sheet(ws_data);
    //     let wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'binary'});
    //
    //     download(this.s2ab(wbout), `clientes-${new Date().toISOString()}.xlsx`, 'application/octet-stream');
    // };

    updateFilteringValues(id, value){
         this.setState({[id]: value})
    }

    parseParameter(parameter){
        return parameter === undefined ? "" : parameter
    }

    parseBool(parameter){
        if(parameter === undefined) return "all"
        return parameter === "true" ? true : false
    }

    export = () => {
        const {comex_companies, cuit, date, eve_companies, mail, name, phone, user_type} = this.state;

        const pcuit = this.parseParameter(cuit)
        const pdate = this.parseParameter(date)
        const peve_companies = this.parseBool(eve_companies)
        const pcomex_companies = this.parseBool(comex_companies)
        const pmail = this.parseParameter(mail)
        const pname = this.parseParameter(name)
        const pphone = this.parseParameter(phone)
        const puser_type = this.parseParameter(user_type)

        getUsersExcel(pname, pmail, pphone, pcuit , puser_type, pdate, pcomex_companies, peve_companies)
    };

    onUpdateUserList = (userList) => {
        this.setState({userList: userList});
        this.getStats();
    };

    render() {
        const {
            userList, selectedUser, popUpHidden, loading
        } = this.state;
        return (
            <div className="dashboard">
                <Header activeTab="users"/>
                <div className="columns homePageColumns statsColums">
                    <div className="column is-two-quarter">
                        <StatsList
                            totalUsers={this.state.labels.totalUsers}
                            eveUsers={this.state.labels.eveUsers}
                            totalEveCompanies={this.state.labels.totalEveCompanies}
                            totalComexCompanies={this.state.labels.totalComexCompanies}
                        />
                    </div>
                    <div className="column is-two-quarter">
                        <div className="tags has-addons" id="tagButtonColumn">
                            {/*<div className="tagButton">*/}
                            {/*    <button className="button is-info is-small" onClick={this.exportAccountants}>*/}
                            {/*        <span>Exportar contadores</span>*/}
                            {/*        <span className="icon is-small">*/}
                            {/*            <i className="fas fa-download"/>*/}
                            {/*        </span>*/}
                            {/*    </button>*/}
                            {/*</div>*/}
                            {/*<div className="tagButton">*/}
                            {/*    <button className="button is-info is-small" onClick={this.exportClients}>*/}
                            {/*        <span>Exportar clientes</span>*/}
                            {/*        <span className="icon is-small">*/}
                            {/*            <i className="fas fa-download"/>*/}
                            {/*        </span>*/}
                            {/*    </button>*/}
                            {/*</div>*/}
                            <div className="tagButton">
                                <button className="button is-info is-small" onClick={this.export}>
                                    <span>Exportar todos</span>
                                    <span className="icon is-small">
                                        <i className="fas fa-download"/>
                                    </span>
                                </button>
                            </div>
                            <div className="tagButton">
                                <button className="button is-info is-small" onClick={this.createNewUser}>
                                    <span>Nuevo Usuario</span>
                                    <span className="icon is-small">
                                        <i className="fas fa-plus"/>
                                    </span>
                                </button>
                            </div>
                            <div className="tagButton">
                                <button className="button is-info is-small" onClick={this.getUsers}>
                                    <span>Actualizar</span>
                                    <span className="icon is-small">
                                        <i className="fas fa-sync"/>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div >
                    <UserTable
                        userList={userList}
                        onDeleteUser={this.onDeleteUser}
                        onSelectUser={this.onSelectUser}
                        onOpenPopUp={this.onOpenPopUp}
                        makeSelectData = {this.makeSelectData}
                        selectedUser={selectedUser}
                        onFiltersChanged={this.updateFilteringValues}
                    />
                </div>
                <UserInfo
                    newSelectedUser={selectedUser}
                    newUserList = {userList}
                    onUpdateUserList={this.onUpdateUserList}
                    popUpHidden={popUpHidden}
                    onClosePopUp={this.onClosePopUp}
                    getUsers = {this.getUsers}
                />
                {loading && <Loading/>}
            </div>
        );
    }
}

export default HomePage;