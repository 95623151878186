import React, {Component} from 'react';
import "react-table/react-table.css";
import {getTable,FIRST_LEVEL_CUIT,SECOND_LEVEL_CUIT} from "./utils"

class ComexCompaniesCuits extends Component {

    constructor(props) {
        super(props);

        this.state = {
            height: 0,
        }
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.onEditCuit = this.onEditCuit.bind(this);
        this.getCuitsInfo = this.getCuitsInfo.bind(this);
    }

     componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({height: window.innerHeight - 550});
    }

    componentWillMount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    customOptionsFilterMethod = (filter, row) => {
      if(!row[filter.id]) {
          return false
      }
      else{
       return row[filter.id] == filter.value
      }
    };

    getCuitsInfo = (row) => {
        const {level,client} = this.props;
        let cuitFamily = {};
        switch (level) {
            case FIRST_LEVEL_CUIT:
                cuitFamily.client = client;
                cuitFamily.firstLevel = row;
                return cuitFamily;
                break;
            case SECOND_LEVEL_CUIT:
                const {firstLevelCuit} = this.props;
                cuitFamily.client= client;
                cuitFamily.firstLevel = firstLevelCuit;
                cuitFamily.secondLevel = row;
                return cuitFamily;
                break;
        }
    }

    onEditCuit = (row) => {
        const {level,onEditCompanyCuit} = this.props;
        const cuitFamily = this.getCuitsInfo(row);
        onEditCompanyCuit(cuitFamily,level);
    };

    onAddCuit = (row,) => {
        const {level,onAddCompanyCuit} = this.props;
        const cuitFamily = this.getCuitsInfo(row);
        onAddCompanyCuit(cuitFamily,level);
    };

    onDeleteCuit = (row) => {
        const {level,onDeleteCompanyCuit} = this.props;
        const cuitFamily = this.getCuitsInfo(row);
        onDeleteCompanyCuit(cuitFamily,level);
    }


    render() {
        const {height} = this.state;
        const {padding, client, level,onDeleteCompanyCuit,onAddCompanyCuit,onEditCompanyCuit,cuits} = this.props;
        return (
            getTable(height,padding,client,level,onDeleteCompanyCuit,onAddCompanyCuit,
                onEditCompanyCuit,this.onEditCuit,this.onDeleteCuit,this.onAddCuit,cuits)
        )

    }

}
export default ComexCompaniesCuits;