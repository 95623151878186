import React, {Component} from 'react';
import "react-table/react-table.css";
import {COMEX_COMPANIES} from "./Constants";
import EveCompanies from "./EveCompanies";
import ComexCompanies from "./ComexCompanies"


class CompaniesTable extends Component {

    constructor(props) {
        super(props);
        this.onEditCompany = this.onEditCompany.bind(this);
        this.onDeleteCompany = this.onDeleteCompany.bind(this);
        this.getCompany = this.getCompany.bind(this);
        this.getTable = this.getTable.bind(this);
    }


    getCompany(data){
        if (this.props.type === COMEX_COMPANIES){
            return {
                name: data.name,
                cuit: data.cuit,
                cert: data.cert,
                user_type: data.user_type,
                cuits: data.cuits,
            }
        }else {
            return  {
                name: data.name,
                cuit: data.cuit,
            }
        }
    }

    onDeleteCompany(data) {
        const company = this.getCompany(data);
        const {type} = this.props;
        const {deleteCompany} = this.props;
        deleteCompany(company,type);
    }

    onEditCompany(data){
        const company = this.getCompany(data);
        const {type} = this.props;
        const {editCompany} = this.props;
        editCompany(company,type);
        }

    getTable(){
        const {companies,type,onDeleteCompanyCuit,onAddCompanyCuit,onEditCompanyCuit} = this.props;
        return type === COMEX_COMPANIES ?
            <ComexCompanies companies={companies} onEditCompany={this.onEditCompany} onDeleteCompany={this.onDeleteCompany}
            onEditCompanyCuit={onEditCompanyCuit} onAddCompanyCuit={onAddCompanyCuit} onDeleteCompanyCuit={onDeleteCompanyCuit}/>
            : <EveCompanies companies={companies} onEditCompany={this.onEditCompany} onDeleteCompany={this.onDeleteCompany} />
    }



    render() {
        return (
            this.getTable()
        )

    }

}
export default CompaniesTable;