import React, {Component} from 'react';
import storage from "local-storage-fallback";
import logo from "./logo.png";
import './App.css';
import history from "./History";

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: null,
            buttons: [
                { "name": "Usuarios", "tab": "users", "history":"/dashboard"},
                { "name": "Vencimientos", "tab": "expirations", "history":"/expirations"},
                { "name": "Oficializados", "tab": "oficialized", "history":"/oficialized"},
                { "name": "Empresas", "tab": "companies", "history":"/companies"}
            ],
        }
    }

    componentWillMount() {
        let {activeTab} = this.props;
        this.setState({activeTab: activeTab})
    }

    logOut = () => {
        storage.setItem('token', "");
        storage.setItem('userName', "");
        history.push("/login");
    };

     onClickButton = (link) =>{
         history.push(link);
    }
    
  render() {

      const {activeTab, buttons, open} = this.state;

      return (

        <nav className="navbar is-light ">
            <div className="navbar-brand">
                <a className="navbar-item" onClick={() => this.onClickButton("/dashboard")}>
                    <img src={logo} height="100" width="100" alt=""/>
                </a>
                <a role="button" className={open?"is-active navbar-burger burger": "navbar-burger burger"} aria-label="menu" aria-expanded="false"
                   data-target="navbarMenu" onClick={()=>this.setState({open:!open})}>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                </a>
            </div>

            <div id="navbarMenu" className={open?"is-active navbar-menu": "navbar-menu"}>
                <div className="navbar-start">
                    {
                buttons.map((button, index) => {
                    return(
                        <a className={activeTab === button.tab ? "navbar-item is-active" : "navbar-item"}
                           onClick={(link) => this.onClickButton(button.history)}>
                            {button.name}
                        </a>
                    )
                    }
            )
                    }
                </div>
                <div className="navbar-end">
                    <div className="navbar-item has-dropdown is-hoverable">
                        <a className="navbar-link is-arrowless">{storage.getItem('userName')}</a>
                        <div className="navbar-dropdown is-light">
                            <a className="navbar-item " onClick={this.logOut}>Cerrar Sesión</a>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    )
  }

}

export default Header;