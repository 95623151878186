import React from "react";
import matchSorter from "match-sorter"
import ReactTable from "react-table";
import ComexCompaniesCuits from "./ComexCompaniesCuits"



const tableStyle = {
  border: "none",
  boxShadow: "none"
};

export const FIRST_LEVEL_CUIT = "first"
export const SECOND_LEVEL_CUIT = "second"

export function formatWord(word){
        let auxWord = word;
        return auxWord.charAt(0).toUpperCase() + auxWord.slice(1);
    }

const customOptionsFilterMethod = (filter, row) => {
      if(!row[filter.id]) {
          return false
      }
      else{
       return row[filter.id] == filter.value
      }
    };


export function getTable(height,padding,client,level,onDeleteCompanyCuit,onAddCompanyCuit,onEditCompanyCuit,onEditCuit,onDeleteCuit,onAddCuit,cuits){
        return level === FIRST_LEVEL_CUIT ?
             <ReactTable
                data={cuits}
                style={tableStyle}
                TheadComponent={_ => null}
                expander
                showPagination={false}
                noDataText="Sin clientes asignados"
                columns={[
                    {
                        columns: [
                            {
                                id: "name",
                                accessor: "name",
                                filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {keys: ["name"]}),
                                filterAll: true
                            },
                            {
                                accessor: "cuit",
                                filterMethod: (filter, row) =>
                                    row[filter.id].startsWith(filter.value)
                            },
                            {
                                Header: "Tipo",
                                accessor: "user_type",
                                filterMethod: (filter, row) =>
                                    row[filter.id].startsWith(filter.value)
                            },
                             {
                                Header: "Certificado",
                                accessor: "cert",
                                 filterMethod:  (filter, row) => customOptionsFilterMethod(filter, row),
                            },
                            {
                                Header: "",
                                filterable: false,
                                Cell: row => (
                                <div>
                                    <span className="icon" onClick={() => onEditCuit(row.original)} >
                                        <i className="fas fa-edit" style={{marginRight: 5, cursor: 'pointer'}}/>
                                    </span>
                                    <span className="icon" onClick={() => onDeleteCuit(row.original)}>
                                        <i className="fas fa-trash" style={{marginRight: 5, cursor: 'pointer'}}/>
                                    </span>
                                     <span className="icon" onClick={() => onAddCuit(row.original)}>
                                        <i className="fas fa-plus" style={{marginRight: 5, cursor: 'pointer'}}/>
                                    </span>
                                </div>)
                            },
                        ]
                    },
                ]}
                className="-striped -highlight"
                pageSize={cuits.length}
                style={{
                    height: height + 'px',
                    padding: padding? '15px' : "0px"
                }}
                SubComponent={(row) => <ComexCompaniesCuits padding={true} level={SECOND_LEVEL_CUIT} firstLevelCuit={row.original} client={client}
                                                            onEditCompanyCuit={onEditCompanyCuit} onAddCompanyCuit={onAddCompanyCuit}
                                                            onDeleteCompanyCuit={onDeleteCompanyCuit} cuits={row.original.cuits}
                />}
            />
            :
             <ReactTable
                data={cuits}
                style={tableStyle}
                TheadComponent={_ => null}
                showPagination={false}
                noDataText="Sin clientes asignados"
                columns={[
                    {
                        columns: [
                            {
                                id: "name",
                                accessor: "name",
                                filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {keys: ["name"]}),
                                filterAll: true
                            },
                            {
                                accessor: "cuit",
                                filterMethod: (filter, row) =>
                                    row[filter.id].startsWith(filter.value)
                            },
                            {
                                Header: "",
                                filterable: false,
                                Cell: row => (
                                <div>
                                    <span className="icon" onClick={() => onEditCuit(row.original)} >
                                        <i className="fas fa-edit" style={{marginRight: 5, cursor: 'pointer'}}/>
                                    </span>
                                    <span className="icon" onClick={() => onDeleteCuit(row.original)}>
                                        <i className="fas fa-trash" style={{marginRight: 5, cursor: 'pointer'}}/>
                                    </span>
                                     <span className="icon" onClick={() => onAddCuit(row.original)} >
                                        <i className="fas fa-plus" style={{marginRight: 5, cursor: 'pointer'}}/>
                                    </span>
                                </div>)
                            },
                        ]
                    },
                ]}
                className="-striped -highlight"
                pageSize={cuits.length}
                style={{
                    height: height + 'px',
                    padding: padding? '15px' : "0px"
                }}
            />
    }