import React, {Component} from 'react';
import 'bulma/css/bulma.css';
import storage from 'local-storage-fallback';
import history from "./History";
import logo from "./logo.png";
import GoogleLogin from 'react-google-login';
import {logIn, onRequestError} from "./Services";
import {config} from "./Utils";

class Login extends Component {

  constructor(props) {
    super(props);

    this.state = {
      error: "",
    }
  }

  render() {

    let {error} = this.state;

    return (
        <div className="Login">
          <header className="App-login-header">
            <img src={logo} height="200" width="200" alt=""/>
          </header>
          <div className="columns">
            <div className="column is-one-third is-offset-one-third">
              <div className="hero">
                <div className="hero-body">
                  <div className="box">

                    <h1 className="title has-text-centered">
                      Iniciar sesión
                    </h1>
                    <h2 className="subtitle has-text-centered	">
                      Por favor, inicie sesión con su cuenta de DASA
                    </h2>

                    <div className="buttons is-centered">
                      <GoogleLogin
                          clientId={config.GOOGLE_CLIENT_ID}
                          buttonText="Ingresar"
                          onSuccess={this.onLoginOk}
                          onFailure={this.onLoginError}
                      />
                    </div>

                  </div>

                  <div className="notification is-warning"
                       hidden={error === ""}>
                    {error}
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
    )
  }

  onLoginError = response => {
    let error = response.details + ". Error code: " + response.error;
    this.setState({loginError: error});
  };

  onLoginOk = googleUser => {
    logIn(googleUser.getAuthResponse().id_token)
        .then(
            response => {
              storage.setItem('userName', response.data.email);
              storage.setItem('userImg', response.data.picture);
              storage.setItem('token', response.data.token);
              storage.setItem('perms-dashboard', response.data.perms.dashboard);
              storage.setItem('perms-reports', response.data.perms.reports);
              storage.setItem('perms-expirations', response.data.perms.expirations);

              let perms = response.data.perms;

              if (perms.dashboard) {
                history.push("/dashboard");
              } else {
                history.push("/informes");
              }

            }
        )
        .catch(
            error => {
              onRequestError(error)
            }
        )
  };

}

export default Login;