import React from 'react'
import {ATTACHMENT_SOURCE} from './Constants'

const AttachmentDropdown = ({
  handleDropdownChange,
  attachment
}) => (
   <div id="attachment_source" className="leftDropdown">
     <nav className="level is-marginless">
       <div className="level-left">
         <label style={{marginRight: '10px', marginBottom: '6px'}}
                className="label  is-small is-pulled-left separate-inputs">
           Origen de adjuntos
         </label>
       </div>
     </nav>
    <div id="attachment_source" className="control has-icons-left">
      <select name="attachment_source" id="user_type"
              value={attachment}
              style={{borderColor: '#dbdbdb', height: '30px', borderRadius: '2px'}}
              onChange={handleDropdownChange}>
        {
          Object.keys(ATTACHMENT_SOURCE).map((item, i) => (
            <option value={ATTACHMENT_SOURCE[item].value} key={i}>
              {ATTACHMENT_SOURCE[item].label}
            </option>
          ))
        }
      </select>
    </div>
  </div>
)

export default AttachmentDropdown